import cn from 'classnames';
import { useFirstMountState } from 'react-use';
import React, {
  ChangeEvent,
  FocusEvent,
  Ref,
  MutableRefObject,
  forwardRef,
  useEffect,
  useState,
  useRef,
} from 'react';

import { CurrencyBoxProps } from './CurrencyBox.types';
import { withFormField } from '../../hocs/withFormField';
import { Image } from '../Image';
import {
  getDecimalCharacter,
  getIconUrl,
  numberFromCurrencyDisplayValue,
  numberFromDecimalDisplayValue,
  numberToCurrencyFormat,
  numberToDecimalFormat,
  numberToDecimalFormatter,
  setNativeValue,
  truncateFractionAndFormat,
} from '../../utils';

import './CurrencyBox.scss';

// eslint-disable-next-line react/display-name
export const CurrencyBox = forwardRef<HTMLInputElement, CurrencyBoxProps>(
  (
    {
      id,
      style,
      className,
      inputClassName,
      buttonClassName,
      variant,
      onFocus,
      onChange,
      onBlur,
      defaultValue,
      value,
      min = -9999999999999.99,
      max = 9999999999999.99,
      currencyDisplay = 'symbol',
      maximumFractionDigits = 2,
      useGrouping,
      locale = navigator.language,
      defaultCurrencyCode = 'usd',
      currencyCode = defaultCurrencyCode,
      disabled,
      hiddenLabel,
      error,
      ...rest
    },
    ref: Ref<HTMLInputElement>,
  ) => {
    const isFirstMount = useFirstMountState();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [focused, setFocused] = useState(false);
    const [displayValue, setDisplayValue] = useState('');
    const [numberValue, setNumberValue] = useState<number>();

    useEffect(() => {
      if (focused) {
        // Don't set the display value while focused on the input
        return;
      }

      setDisplayValue(
        numberToCurrencyFormat(isFirstMount ? value ?? defaultValue : value, {
          locale,
          currencyCode,
          currencyDisplay,
          maximumFractionDigits,
          useGrouping,
        }),
      );
    }, [
      focused,
      value,
      defaultValue,
      locale,
      currencyCode,
      currencyDisplay,
      maximumFractionDigits,
      useGrouping,
    ]);

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
      setFocused(true);

      const numStrValue = numberFromCurrencyDisplayValue(displayValue, {
        locale,
        currencyCode,
        currencyDisplay,
        maximumFractionDigits,
        useGrouping,
      });

      const newDisplayValue = numberToDecimalFormat(numStrValue, {
        locale,
        maximumFractionDigits,
        useGrouping,
      });

      setDisplayValue(newDisplayValue);
      onFocus?.(e);
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      setFocused(false);

      const newDisplayValue = numberToCurrencyFormat(numberValue, {
        locale,
        currencyCode,
        currencyDisplay,
        maximumFractionDigits,
        useGrouping,
      });

      // Wait until the next event loop to set the display value
      setTimeout(() => setDisplayValue(newDisplayValue), 0);
      onBlur?.(e);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      let strValue = e.target.value;

      // Clear number value when no input
      if (!strValue.length) {
        setDisplayValue(strValue);
        setNumberValue(undefined);
        onChange?.(undefined);
        return;
      }

      // Return when starting negative value and min is at least zero
      if (strValue === '-' && min !== undefined && Number(min) >= 0) {
        return;
      }

      // Only set display value when input is negative symbol
      if (strValue === '-') {
        setDisplayValue(strValue);
        return;
      }

      // Convert the display value to a number
      let numStrValue = numberFromDecimalDisplayValue(strValue, {
        locale,
        // Do not truncate here
        maximumFractionDigits: 20,
        useGrouping,
      }) as number;

      if (max !== undefined) {
        // Immediately return when value is higher than max
        if (numStrValue !== Math.min(Number(max), numStrValue)) {
          return;
        }
      }

      if (min !== undefined) {
        // Use min if value is lower than min
        numStrValue = Math.max(Number(min), numStrValue);
      }

      // Get the formatter
      const formatter = numberToDecimalFormatter({
        locale,
        // Do not truncate here
        maximumFractionDigits: 20,
        useGrouping,
      });

      // Format to parts, then truncate and format,
      // and then convert the formatted value to a number
      numStrValue = numberFromDecimalDisplayValue(
        truncateFractionAndFormat(
          formatter.formatToParts(numStrValue),
          maximumFractionDigits,
        ),
        {
          locale,
          // Truncate here
          maximumFractionDigits,
          useGrouping,
        },
      ) as number;

      const fractionDigits =
        (strValue.split(getDecimalCharacter())?.[1]?.length ?? 0) - 1;

      const preventFractionDigits =
        maximumFractionDigits === 0 || fractionDigits >= maximumFractionDigits;

      // Update the display value when there are no fraction digits,
      // or the fraction digits are not more than the maximum allowed
      if (preventFractionDigits) {
        strValue = numberToDecimalFormat(numStrValue, {
          locale,
          // Truncate here
          maximumFractionDigits,
          useGrouping,
        });
      }

      setDisplayValue(strValue);
      setNumberValue(numStrValue);
      onChange?.(numStrValue);
    };

    const handleClearClick = () => {
      if (!inputRef.current) {
        return;
      }
      setNativeValue(inputRef.current, '');
      inputRef.current.dispatchEvent(new Event('input', { bubbles: true }));
      setTimeout(() => inputRef.current?.focus(), 0);
    };

    const hasValue = displayValue !== '';

    return (
      <div
        data-testid="currencybox"
        className={cn('lex-currencybox', className)}
        style={style}
      >
        <div>
          <div>
            {hiddenLabel && (
              <label className="screen-reader" htmlFor={id}>
                {hiddenLabel}
              </label>
            )}
            <input
              id={id}
              data-testid="currencybox-input"
              className={cn(
                'lex-currencybox__input',
                variant && `lex-currencybox__input--${variant}`,
                error && 'lex-currencybox__input--error',
                disabled && 'lex-currencybox__input--disabled',
                inputClassName,
              )}
              ref={(node) => {
                inputRef.current = node;
                if (typeof ref === 'function') {
                  ref(node);
                } else if (ref) {
                  (ref as MutableRefObject<HTMLDivElement | null>).current =
                    node;
                }
              }}
              onFocus={handleFocus}
              onChange={handleChange}
              onBlur={handleBlur}
              value={displayValue}
              min={min}
              max={max}
              disabled={disabled}
              style={style}
              lang={locale}
              {...rest}
            />
            {!disabled && hasValue && (
              <button
                data-testid="currencybox-button"
                className={cn('lex-currencybox__clear', buttonClassName)}
                type="button"
                onClick={handleClearClick}
              >
                <Image src={getIconUrl('clear')} />
              </button>
            )}
          </div>
        </div>
      </div>
    );
  },
);

export default CurrencyBox;

export const CurrencyBoxFormField = withFormField(CurrencyBox);
