import React from 'react';
import cn from 'classnames';
import { Tabs as AntTabs } from 'antd';
import AntdConfigProvider from 'antd/lib/config-provider';

import { TabsProps, TabPaneProps } from './Tabs.types';
import { getAntdLocale } from '../../utils';

import './Tabs.scss';

export const TabPane: React.FC<TabPaneProps> = ({ className, ...rest }) => {
  return (
    <AntTabs.TabPane
      data-testid="tabs-tab-pane"
      className={cn('lex-tabs__tab-pane', className)}
      {...rest}
    />
  );
};

interface TabsFC<T> extends React.FC<T> {
  TabPane: typeof TabPane;
}

export const Tabs: TabsFC<TabsProps> = ({
  className,
  variant,
  locale = navigator.language,
  ...rest
}) => {
  const antdLocale = getAntdLocale(locale);

  return (
    <AntdConfigProvider locale={antdLocale}>
      <AntTabs
        data-testid="tabs"
        className={cn('lex-tabs', variant && `lex-tabs--${variant}`, className)}
        animated={false}
        {...rest}
      />
    </AntdConfigProvider>
  );
};

Tabs.TabPane = TabPane;

export default Tabs;
