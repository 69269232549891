import React from 'react';
import cn from 'classnames';
import { Switch as AntSwitch } from 'antd';
import AntdConfigProvider from 'antd/lib/config-provider';

import { SwitchProps } from './Switch.types';
import { getAntdLocale } from '../../utils';

import './Switch.scss';

export const Switch: React.FC<SwitchProps> = ({
  style,
  className,
  variant = 'primary',
  disabled,
  locale = navigator.language,
  ...rest
}) => {
  const antdLocale = getAntdLocale(locale);
  return (
    <div
      data-testid="switch"
      className={cn(
        'lex-switch',
        variant && `lex-switch--${variant}`,
        disabled && 'lex-switch--disabled',
        className,
      )}
      style={style}
    >
      <AntdConfigProvider locale={antdLocale}>
        <AntSwitch data-testid="switch-toggle" disabled={disabled} {...rest} />
      </AntdConfigProvider>
    </div>
  );
};

export default Switch;
