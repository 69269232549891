import React, { useRef, useState } from 'react';
import cn from 'classnames';
import parse from 'html-react-parser';
import TruncateMarkup, {
  TruncateProps as TruncateMarkupProps,
} from 'react-truncate-markup';

import { TruncateProps } from './Truncate.types';
import { Image } from '../Image';
import { getIconUrl } from '../../utils';

import './Truncate.scss';

const defShowMoreIconSrc = getIconUrl('format_indent_increase');
const defShowLessIconSrc = getIconUrl('format_indent_decrease');

// NOTE: React v18 requires the children prop to be listed explicitly,
// and the react-truncate-markup component does not do this
interface TruncateMarkupWithChildrenProps extends TruncateMarkupProps {
  children?: React.ReactNode;
}

const TruncateMarkupWithChildren: React.FC<TruncateMarkupWithChildrenProps> = (
  props,
) => {
  return <TruncateMarkup {...props} />;
};

export const Truncate: React.FC<TruncateProps> = ({
  className,
  style,
  showMoreText,
  moreText = 'More',
  lessText = 'Less',
  showMoreIcon,
  moreIconSrc,
  lessIconSrc,
  children,
  ...rest
}) => {
  if (!moreIconSrc) moreIconSrc = defShowMoreIconSrc;
  if (!lessIconSrc) lessIconSrc = defShowLessIconSrc;

  const elementRef = useRef<HTMLDivElement>(null);
  const [shouldTruncate, setShouldTruncate] = useState(
    showMoreText || showMoreIcon,
  );

  if (!children) {
    return null;
  }

  const toggleTruncate = () => {
    setShouldTruncate(!shouldTruncate);
  };

  const content = React.isValidElement(children) ? (
    children
  ) : (
    <span>{parse(String(children).trim())}</span>
  );

  if (!showMoreText && !showMoreIcon) {
    return (
      <div
        data-testid="truncate"
        className={cn('lex-truncate', className)}
        style={style}
        ref={elementRef}
      >
        <TruncateMarkupWithChildren {...rest}>
          {content}
        </TruncateMarkupWithChildren>
      </div>
    );
  }

  const showMoreLink = (
    <span
      className={cn(
        'lex-truncate__span',
        !showMoreText && showMoreIcon && 'lex-truncate__span--icon-only',
      )}
    >
      ...&nbsp;
      {showMoreText && (
        <span
          data-testid="truncate-more-text"
          className={cn('lex-truncate__text')}
          onClick={toggleTruncate}
        >
          {moreText}
        </span>
      )}
      {showMoreIcon && (
        <span
          data-testid="truncate-more-icon"
          className={cn('lex-truncate__icon')}
          onClick={toggleTruncate}
        >
          <Image src={moreIconSrc} />
        </span>
      )}
    </span>
  );

  if (shouldTruncate) {
    return (
      <div
        data-testid="truncate"
        className={cn('lex-truncate', className)}
        style={style}
        ref={elementRef}
      >
        <TruncateMarkupWithChildren ellipsis={showMoreLink} {...rest}>
          {content}
        </TruncateMarkupWithChildren>
      </div>
    );
  }

  const showLessLink = (
    <span
      className={cn(
        'lex-truncate__span',
        !showMoreText && showMoreIcon && 'lex-truncate__span--icon-only',
      )}
    >
      &nbsp;
      {showMoreText && (
        <span
          data-testid="truncate-less-text"
          className={cn('lex-truncate__text')}
          onClick={toggleTruncate}
        >
          {lessText}
        </span>
      )}
      {showMoreIcon && (
        <span
          data-testid="truncate-less-icon"
          className={cn('lex-truncate__icon')}
          onClick={toggleTruncate}
        >
          <Image src={lessIconSrc} />
        </span>
      )}
    </span>
  );

  return (
    <div
      data-testid="truncate"
      className={cn('lex-truncate', className)}
      style={style}
    >
      <div>
        {content}
        {showLessLink}
      </div>
    </div>
  );
};

export default Truncate;
