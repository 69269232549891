export interface StringToNumberParams {
  locale?: string;
}

export interface NumberToDecimalFormatParams extends StringToNumberParams {
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  useGrouping?: boolean;
}

export interface NumberToCurrencyFormatParams
  extends NumberToDecimalFormatParams {
  currencyCode?: string;
  currencyDisplay?: string;
}

export const getDecimalCharacter = (locale = navigator.language): string => {
  try {
    const decimalChar = new Intl.NumberFormat(locale)
      .format(1.1)
      .replace(/1/g, '');

    return decimalChar || '';
  } catch {
    return '';
  }
};

export const getGroupingCharacter = (locale = navigator.language): string => {
  try {
    const groupingChar = new Intl.NumberFormat(locale)
      .format(1111)
      .replace(/1/g, '');

    return groupingChar || '';
  } catch {
    return '';
  }
};

export const numberFromCurrencyDisplayValue = (
  input: string | null | undefined,
  params: {
    locale?: string;
    currencyCode?: string;
    currencyDisplay?: string;
    maximumFractionDigits?: number;
    useGrouping?: boolean;
  },
): number | undefined => {
  const { locale } = params;
  const stringValue = stringFromCurrencyDisplayValue(input, params);
  const numValue = stringToCurrencyNumber(stringValue, { locale });
  return numValue;
};

export const numberFromDecimalDisplayValue = (
  input: string | null | undefined,
  params: {
    locale?: string;
    maximumFractionDigits?: number;
    useGrouping?: boolean;
  },
): number | undefined => {
  const { locale } = params;
  const stringValue = stringFromDecimalDisplayValue(input, params);
  const numValue = stringToDecimalNumber(stringValue, { locale });
  return numValue;
};

export const numberToCurrencyFormat = (
  numValue: number | string | null | undefined,
  params: NumberToCurrencyFormatParams = {},
): string => {
  if (!Number.isFinite(Number.parseFloat(numValue as string))) {
    return '';
  }

  const val = Number(numValue);
  const formatter = numberToCurrencyFormatter(params);

  return formatter.format(val);
};

export const numberToCurrencyFormatter = (
  params: NumberToCurrencyFormatParams = {},
): Intl.NumberFormat => {
  const {
    locale,
    currencyCode,
    currencyDisplay,
    minimumFractionDigits,
    maximumFractionDigits,
    useGrouping,
  } = {
    locale: navigator.language,
    currencyCode: 'usd',
    currencyDisplay: 'symbol',
    maximumFractionDigits: 2,
    useGrouping: false,
    ...JSON.parse(JSON.stringify(params)),
  } as NumberToCurrencyFormatParams;

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay,
    minimumFractionDigits: minimumFractionDigits ?? maximumFractionDigits,
    maximumFractionDigits,
    useGrouping,
  });

  return formatter;
};

export const numberToDecimalFormat = (
  numValue: number | string | null | undefined,
  params: NumberToDecimalFormatParams = {},
): string => {
  if (!Number.isFinite(Number.parseFloat(numValue as string))) {
    return '';
  }

  const val = Number(numValue);
  const formatter = numberToDecimalFormatter(params);

  return formatter.format(val);
};

export const numberToDecimalFormatter = (
  params: NumberToDecimalFormatParams = {},
): Intl.NumberFormat => {
  const {
    locale,
    minimumFractionDigits = 0,
    maximumFractionDigits = 2,
    useGrouping,
  } = {
    locale: navigator.language,
    useGrouping: false,
    ...JSON.parse(JSON.stringify(params)),
  } as NumberToDecimalFormatParams;

  const formatter = new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits,
    maximumFractionDigits,
    useGrouping,
  });

  return formatter;
};

export const stringFromCurrencyDisplayValue = (
  input: string | null | undefined,
  params: {
    locale?: string;
    currencyCode?: string;
    currencyDisplay?: string;
    maximumFractionDigits?: number;
    useGrouping?: boolean;
  },
): string => {
  const { locale, maximumFractionDigits, useGrouping } = params;
  const numValue = stringToCurrencyNumber(input, { locale });
  const stringValue = numberToCurrencyFormat(numValue, {
    locale,
    maximumFractionDigits,
    useGrouping,
  });
  return stringValue;
};

export const stringFromDecimalDisplayValue = (
  input: string | null | undefined,
  params: {
    locale?: string;
    maximumFractionDigits?: number;
    useGrouping?: boolean;
  },
): string => {
  const { locale, maximumFractionDigits, useGrouping } = params;
  const numValue = stringToDecimalNumber(input, { locale });
  const stringValue = numberToDecimalFormat(numValue, {
    locale,
    maximumFractionDigits,
    useGrouping,
  });
  return stringValue;
};

export const stringToCurrencyNumber = (
  strValue: string | null | undefined,
  params: StringToNumberParams = {},
): number | undefined => {
  const { locale } = {
    locale: navigator.language,
    ...JSON.parse(JSON.stringify(params)),
  } as StringToNumberParams;

  const numValue = stringToDecimalNumber(strValue, { locale });

  if (numValue === undefined) {
    return undefined;
  }

  const rounded = Math.round((numValue + Number.EPSILON) * 100) / 100;
  return rounded;
};

export const stringToDecimalNumber = (
  strValue: string | null | undefined,
  params: StringToNumberParams = {},
): number | undefined => {
  try {
    if (strValue === null || strValue === undefined || !strValue.length) {
      return undefined;
    }

    let reversedVal = strValue;

    const { locale } = {
      locale: navigator.language,
      ...JSON.parse(JSON.stringify(params)),
    } as StringToNumberParams;

    const grouping = getGroupingCharacter(locale);

    // remove locale grouping, if it exists
    if (grouping) {
      reversedVal = reversedVal.replace(new RegExp(`\\${grouping}`, 'g'), '');
    }

    const decimalChar = getDecimalCharacter(locale);

    // replace locale decimal with dot, if it exists
    if (decimalChar) {
      reversedVal = reversedVal.replace(
        new RegExp(`\\${decimalChar}`, 'g'),
        '.',
      );
    }

    // remove everything except the digits, dot, and negative symbol
    reversedVal = reversedVal.replace(/[^0-9.-]/g, '');

    if (!reversedVal.length) {
      return undefined;
    }

    const numValue = Number(reversedVal);

    if (isNaN(numValue)) {
      return undefined;
    }

    return numValue;
  } catch {
    return undefined;
  }
};

export const truncateFractionAndFormat = (
  parts: Intl.NumberFormatPart[],
  digits = 0,
): string => {
  return parts
    .map(({ type, value }) => {
      if (type !== 'fraction' || !value || value.length < digits) {
        return value;
      }

      let retVal = '';
      for (
        let idx = 0, counter = 0;
        idx < value.length && counter < digits;
        idx++
      ) {
        if (value[idx] !== '0') {
          counter++;
        }
        retVal += value[idx];
      }
      return retVal;
    })
    .reduce((string, part) => string + part);
};
