import cn from 'classnames';
import { useFirstMountState } from 'react-use';
import React, {
  ChangeEvent,
  FocusEvent,
  MutableRefObject,
  Ref,
  forwardRef,
  useEffect,
  useState,
  useRef,
} from 'react';

import { NumericBoxProps } from './NumericBox.types';
import { withFormField } from '../../hocs/withFormField';
import { Image } from '../Image';
import {
  getDecimalCharacter,
  getIconUrl,
  numberFromDecimalDisplayValue,
  numberToDecimalFormat,
  numberToDecimalFormatter,
  setNativeValue,
  truncateFractionAndFormat,
} from '../../utils';

import './NumericBox.scss';

// eslint-disable-next-line react/display-name
export const NumericBox = forwardRef<HTMLInputElement, NumericBoxProps>(
  (
    {
      id,
      style,
      className,
      inputClassName,
      buttonClassName,
      variant,
      onChange,
      onBlur,
      defaultValue,
      value,
      min = -9999999999999.99,
      max = 9999999999999.99,
      maximumFractionDigits = 2,
      useGrouping,
      locale = navigator.language,
      disabled,
      hiddenLabel,
      error,
      ...rest
    },
    ref: Ref<HTMLInputElement>,
  ) => {
    const isFirstMount = useFirstMountState();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [displayValue, setDisplayValue] = useState('');
    const [numberValue, setNumberValue] = useState<number>();

    useEffect(() => {
      setDisplayValue(
        numberToDecimalFormat(isFirstMount ? value ?? defaultValue : value, {
          locale,
          // Truncate here
          maximumFractionDigits,
          useGrouping,
        }),
      );
    }, [value, defaultValue, locale, maximumFractionDigits, useGrouping]);

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      const newDisplayValue = numberToDecimalFormat(numberValue, {
        locale,
        maximumFractionDigits,
        useGrouping,
      });

      // Wait until the next event loop to set the display value
      setTimeout(() => setDisplayValue(newDisplayValue), 0);
      onBlur?.(e);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      let strValue = e.target.value;

      // Clear number value when no input
      if (!strValue.length) {
        setDisplayValue(strValue);
        setNumberValue(undefined);
        onChange?.(undefined);
        return;
      }

      // Return when starting negative value and min is at least zero
      if (strValue === '-' && min !== undefined && Number(min) >= 0) {
        return;
      }

      // Only set display value when input is negative symbol
      if (strValue === '-') {
        setDisplayValue(strValue);
        return;
      }

      // Convert the display value to a number
      let numStrValue = numberFromDecimalDisplayValue(strValue, {
        locale,
        // Do not truncate here
        maximumFractionDigits: 20,
        useGrouping,
      }) as number;

      if (max !== undefined) {
        // Immediately return when value is higher than max
        if (numStrValue !== Math.min(Number(max), numStrValue)) {
          return;
        }
      }

      if (min !== undefined) {
        // Use min if value is lower than min
        numStrValue = Math.max(Number(min), numStrValue);
      }

      // Get the formatter
      const formatter = numberToDecimalFormatter({
        locale,
        // Do not truncate here
        maximumFractionDigits: 20,
        useGrouping,
      });

      // Format to parts, then truncate and format,
      // and then convert the formatted value to a number
      numStrValue = numberFromDecimalDisplayValue(
        truncateFractionAndFormat(
          formatter.formatToParts(numStrValue),
          maximumFractionDigits,
        ),
        {
          locale,
          // Truncate here
          maximumFractionDigits,
          useGrouping,
        },
      ) as number;

      const fractionDigits =
        (strValue.split(getDecimalCharacter())?.[1]?.length ?? 0) - 1;

      const preventFractionDigits =
        maximumFractionDigits === 0 || fractionDigits >= maximumFractionDigits;

      // Update the display value when there are no fraction digits,
      // or the fraction digits are not more than the maximum allowed
      if (preventFractionDigits) {
        strValue = numberToDecimalFormat(numStrValue, {
          locale,
          // Truncate here
          maximumFractionDigits,
          useGrouping,
        });
      }

      setDisplayValue(strValue);
      setNumberValue(numStrValue);
      onChange?.(numStrValue);
    };

    const handleClearClick = () => {
      if (!inputRef.current) {
        return;
      }
      setNativeValue(inputRef.current, '');
      inputRef.current.dispatchEvent(new Event('input', { bubbles: true }));
      setTimeout(() => inputRef.current?.focus(), 0);
    };

    const hasValue = displayValue !== '';

    return (
      <div
        data-testid="numericbox"
        className={cn('lex-numericbox', className)}
        style={style}
      >
        <div>
          {hiddenLabel && (
            <label className="screen-reader" htmlFor={id}>
              {hiddenLabel}
            </label>
          )}
          <input
            id={id}
            data-testid="numericbox-input"
            className={cn(
              'lex-numericbox__input',
              variant && `lex-numericbox__input--${variant}`,
              error && 'lex-numericbox__input--error',
              disabled && 'lex-numericbox__input--disabled',
              inputClassName,
            )}
            ref={(node) => {
              inputRef.current = node;
              if (typeof ref === 'function') {
                ref(node);
              } else if (ref) {
                (ref as MutableRefObject<HTMLDivElement | null>).current = node;
              }
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            value={displayValue}
            min={min}
            max={max}
            disabled={disabled}
            lang={locale}
            {...rest}
          />
          {!disabled && hasValue && (
            <button
              data-testid="numericbox-button"
              className={cn('lex-numericbox__clear', buttonClassName)}
              type="button"
              onClick={handleClearClick}
            >
              <Image src={getIconUrl('clear')} />
            </button>
          )}
        </div>
      </div>
    );
  },
);

export default NumericBox;

export const NumericBoxFormField = withFormField(NumericBox);
