import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import AntdModal from 'antd/lib/modal';
import AntdConfigProvider from 'antd/lib/config-provider';

import { ModalProps } from './Modal.types';
import Button from '../Button';
import Image from '../Image';
import ScrollHint from '../ScrollHint';
import { BELOW_MD, MAX_VIEWPORT_PERCENTAGE } from '../../constants';
import { getAntdLocale, getIconUrl } from '../../utils';
import PrintButton from '../PrintButton/PrintButton';

import './Modal.scss';

const closeIconUrl = getIconUrl('close');

export const Modal: React.FC<ModalProps> = ({
  children,
  title,
  closable = false,
  visible,
  open,
  onCancel,
  className,
  containerClassName,
  wrapClassName,
  footerClassName,
  cancelButtonConfirm,
  cancelButtonConfirmClassName,
  cancelButtonConfirmText = 'Are you sure?',
  cancelButtonConfirmOkText = 'Yes',
  cancelButtonConfirmCancelText = 'No',
  cancelButtonProps,
  actionButtonsProps = [],
  viewportMinWidth = 20,
  viewportWidth = 60,
  viewportMaxWidth = MAX_VIEWPORT_PERCENTAGE,
  viewportMinHeight = 20,
  viewportHeight,
  viewportMaxHeight = MAX_VIEWPORT_PERCENTAGE,
  scrollHintProps = { type: 'both' },
  displayPrintButton = false,
  printButtonProps = {},
  locale = navigator.language,
  titleActions,
  ...rest
}) => {
  const [id] = useState<string>(Math.random().toString(16).slice(2));
  const [container, setContainer] = useState<HTMLDivElement>();
  const [isModalClosable, setIsModalClosable] = useState(closable);
  const [isModalVisible, setIsModalVisible] = useState(visible || open);

  const isDeviceBelowMd = useMediaQuery({ query: BELOW_MD });

  const antdLocale = getAntdLocale(locale);

  useLayoutEffect(() => {
    if (document.getElementById(id)) {
      return;
    }

    // Create modal container
    const container = document.createElement('div');
    container.id = id;
    container.className = cn('lex-modal__container', containerClassName);
    document.body.append(container);
    setContainer(container);

    return () => container?.remove();
  }, [id, containerClassName]);

  useEffect(() => {
    // If new closable prop value is passed in
    setIsModalClosable(closable);
  }, [closable]);

  useEffect(() => {
    // If new visible prop value is passed in
    // NOTE: VISIBLE HAS BEEN DEPRECATED
    setIsModalVisible(visible || open);
  }, [visible, open]);

  const effectDeps = [
    container,
    isModalVisible,
    isDeviceBelowMd,
    window.innerWidth,
    window.innerHeight,
    viewportMinWidth,
    viewportWidth,
    viewportMaxWidth,
    viewportMinHeight,
    viewportHeight,
    viewportMaxHeight,
  ];

  const setDimensions = useCallback(() => {
    if (!isModalVisible) {
      return;
    }

    if (!container) {
      console.error(`id ${id} not found`);
      return;
    }

    const modalDiv = container.querySelector('.lex-modal') as HTMLDivElement;

    if (!modalDiv) {
      console.error('lex-modal not found');
      return;
    }

    const contentDiv = modalDiv.querySelector(
      '.ant-modal-content',
    ) as HTMLDivElement;

    if (!contentDiv) {
      console.error('ant-modal-content not found');
      return;
    }

    // Antd nonsense
    modalDiv.style.width = 'auto';
    modalDiv.style.transformOrigin = 'unset';

    // Devices smaller than medium should render full screen
    const vpWidth = isDeviceBelowMd ? MAX_VIEWPORT_PERCENTAGE : viewportWidth;
    const vpMaxHeight = isDeviceBelowMd
      ? MAX_VIEWPORT_PERCENTAGE
      : viewportMaxHeight;

    const calcValue = (innerValue: number, viewportValue: number) => {
      const pxVal = innerValue * (viewportValue / 100);
      return `${pxVal}px`;
    };

    // Calculate widths based on viewport
    if (viewportMinWidth) {
      const val = calcValue(window.innerWidth, viewportMinWidth);
      contentDiv.style.minWidth = val;
    }
    if (vpWidth) {
      const val = calcValue(window.innerWidth, vpWidth);
      contentDiv.style.width = val;
    }
    if (viewportMaxWidth) {
      const val = calcValue(window.innerWidth, viewportMaxWidth);
      contentDiv.style.maxWidth = val;
    }
    if (viewportMinHeight) {
      const val = calcValue(window.innerHeight, viewportMinHeight);
      contentDiv.style.minHeight = val;
    }
    if (viewportHeight) {
      const val = calcValue(window.innerHeight, viewportHeight);
      contentDiv.style.height = val;
    }
    if (vpMaxHeight) {
      const val = calcValue(window.innerHeight, vpMaxHeight);
      contentDiv.style.maxHeight = val;
    }

    const visibility = window
      .getComputedStyle(modalDiv)
      .getPropertyValue('visibility');

    // Set modal to visible if it is not already
    if (visibility !== 'visible') {
      setTimeout(() => (modalDiv.style.visibility = 'visible'), 300);
    }
  }, effectDeps);

  useEffect(() => {
    if (!container) {
      return;
    }

    const mo = new MutationObserver(() => {
      if (container.querySelector('.lex-modal')) {
        setTimeout(setDimensions, 0);
      }
    });

    // Wait for the parent container
    mo.observe(container, {
      childList: true,
      subtree: true,
    });

    return () => mo.disconnect();
  }, [container, setDimensions]);

  useEffect(() => {
    window.addEventListener('resize', setDimensions);
    return () => window.removeEventListener('resize', setDimensions);
  }, effectDeps);

  useEffect(() => {
    window.addEventListener('orientationchange', setDimensions);
    return () => window.removeEventListener('orientationchange', setDimensions);
  }, effectDeps);

  // Cancel button
  let cancelButton = null;
  // TODO: remove cancelButtonConfirm prop check once fully deprecated
  if (cancelButtonProps && !cancelButtonConfirm) {
    cancelButton = <Button {...cancelButtonProps} />;
  } else if (cancelButtonProps && cancelButtonConfirm) {
    // Note: We won't need this conditional check once deprecated cancel props are no longer being used
    cancelButton = (
      <Button
        {...cancelButtonProps}
        data-testid="modal-popconfirm"
        popconfirmProps={{
          cancelText: cancelButtonConfirmCancelText,
          className: cn('lex-modal__confirm', cancelButtonConfirmClassName),
          icon: <Image src={getIconUrl('warning')} />,
          okText: cancelButtonConfirmOkText,
          title: <span>{cancelButtonConfirmText}</span>,
          open: false,
        }}
      />
    );
  }

  // Action buttons
  const actionButtons = actionButtonsProps.map((actionButtonProps, idx) => {
    return <Button key={actionButtonProps.id || idx} {...actionButtonProps} />;
  });

  // Footer
  const footer =
    !cancelButton && !actionButtons.length ? null : (
      <div
        data-testid="modal-footer"
        className={cn('lex-modal__footer', footerClassName)}
      >
        {cancelButton}
        {actionButtons}
      </div>
    );

  // Close button
  let handleCancel;
  if (isModalClosable && cancelButtonProps && cancelButtonProps.onClick) {
    handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      cancelButtonProps &&
        cancelButtonProps.onClick &&
        cancelButtonProps.onClick(
          e as React.MouseEvent<HTMLButtonElement, MouseEvent>,
        );
    };
  }

  const content = !scrollHintProps ? (
    children
  ) : (
    <ScrollHint>{children}</ScrollHint>
  );

  let titleContent = closable && !title ? <></> : title;

  const hasCustomTitle = displayPrintButton || !!titleActions;

  if (hasCustomTitle) {
    titleContent = (
      <div
        className={cn(
          'lex-modal__custom-title-container',
          displayPrintButton && 'lex-modal__custom-title-container--print',
        )}
      >
        {title && <h1 className={cn('lex-modal__custom-title')}>{title}</h1>}
        {titleActions}
        {displayPrintButton && (
          <div
            className={cn(closable ? 'lex-modal__custom-title-print-btn' : '')}
          >
            <PrintButton {...printButtonProps} />
          </div>
        )}
      </div>
    );
  }

  return (
    <AntdConfigProvider locale={antdLocale}>
      <AntdModal
        data-testid="modal"
        className={cn('lex-modal', className)}
        wrapClassName={cn('lex-modal__wrap', wrapClassName)}
        getContainer={container ?? document.body}
        title={titleContent}
        centered={false}
        destroyOnClose
        closable={isModalClosable}
        open={isModalVisible}
        onCancel={handleCancel || onCancel}
        footer={footer}
        closeIcon={
          <Image
            className={cn(
              'lex-modal__close-icon',
              displayPrintButton ? 'lex-modal__close-icon-print' : '',
            )}
            src={closeIconUrl}
          />
        }
        {...rest}
      >
        {content}
      </AntdModal>
    </AntdConfigProvider>
  );
};

export default Modal;
