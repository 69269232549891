import React from 'react';
import cn from 'classnames';
import AntRadio from 'antd/lib/radio';
import AntdConfigProvider from 'antd/lib/config-provider';

import { RadioProps } from './Radio.types';
import { getAntdLocale } from '../../utils';

import './Radio.scss';

export const Radio: React.FC<RadioProps> = ({
  id,
  className,
  variant = 'primary',
  disabled,
  error,
  locale = navigator.language,
  ...rest
}) => {
  const antdLocale = getAntdLocale(locale);

  return (
    <AntdConfigProvider locale={antdLocale}>
      <AntRadio
        id={id}
        data-testid="radio"
        className={cn(
          'lex-radio',
          variant && `lex-radio--${variant}`,
          error && 'lex-radio--error',
          disabled && 'lex-radio--disabled',
          className,
        )}
        disabled={disabled}
        {...rest}
      />
    </AntdConfigProvider>
  );
};

export default Radio;
