import React from 'react';
import cn from 'classnames';
import AntTag from 'antd/lib/tag';
import AntdConfigProvider from 'antd/lib/config-provider';

import { Image } from '../Image';
import { TagProps } from './Tag.types';
import { getAntdLocale, getIconUrl } from '../../utils';

import './Tag.scss';

export const Tag: React.FC<TagProps> = ({
  className,
  variant = 'primary',
  disabled,
  closable = true,
  children,
  locale = navigator.language,
  ...rest
}) => {
  const antdLocale = getAntdLocale(locale);

  return (
    <AntdConfigProvider locale={antdLocale}>
      <AntTag
        data-testid="tag"
        className={cn(
          'lex-tag',
          variant && `lex-tag--${variant}`,
          disabled && 'lex-tag--disabled',
          className,
        )}
        closable={closable && !disabled}
        closeIcon={
          <Image
            className={cn('lex-tag__close-icon')}
            src={getIconUrl('clear')}
          />
        }
        {...rest}
      >
        {children}
      </AntTag>
    </AntdConfigProvider>
  );
};

export default Tag;
