import React, { ReactElement } from 'react';
import cn from 'classnames';
import { Collapse as AntdCollapse } from 'antd';
import AntdConfigProvider from 'antd/lib/config-provider';

import { CollapseProps, CollapsePanelProps } from './Collapse.types';
import { Image } from '../Image';
import { getAntdLocale, getIconUrl } from '../../utils';

import './Collapse.scss';

export const CollapsePanel: React.FC<CollapsePanelProps> = ({
  className,
  key,
  ...rest
}) => (
  <AntdCollapse.Panel
    data-testid={`lex-collapse-panel-${key}`}
    className={cn('lex-collapse__panel', className)}
    key={key}
    {...rest}
  />
);

export const Collapse: React.FC<CollapseProps> = ({
  className,
  panelClassName,
  expandIconClassName,
  expandIconImageSrc = getIconUrl('expand_more'),
  collapseIconImageSrc = getIconUrl('expand_less'),
  expandIcon,
  panels = [],
  locale = navigator.language,
  ...rest
}) => {
  const antdLocale = getAntdLocale(locale);

  const collapsePanels: ReactElement<CollapsePanelProps>[] = panels.map(
    ({ key, ...rest }) => {
      return <CollapsePanel className={panelClassName} key={key} {...rest} />;
    },
  );

  const defaultExpandIcon: CollapseProps['expandIcon'] = ({ isActive }) => (
    <Image
      className={cn(`lex-collapse__expand-icon`, expandIconClassName)}
      src={isActive ? collapseIconImageSrc : expandIconImageSrc}
    />
  );

  return (
    <AntdConfigProvider locale={antdLocale}>
      <AntdCollapse
        data-testid="lex-collapse"
        className={cn('lex-collapse', className)}
        expandIcon={expandIcon ?? defaultExpandIcon}
        {...rest}
      >
        {collapsePanels}
      </AntdCollapse>
    </AntdConfigProvider>
  );
};

export default Collapse;
