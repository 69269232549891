import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useDebounce, useFirstMountState } from 'react-use';
import { AutoComplete as AntdAutoComplete, Select as AntdSelect } from 'antd';
import AntdConfigProvider from 'antd/lib/config-provider';

import { Image } from '../Image';
import { Tooltip } from '../Tooltip';
import { Pagination } from '../Pagination';
import { Spinner } from '../Spinner';
import { AutoCompleteProps } from './AutoComplete.types';
import { Option } from '../Select/Select.types';
import { getIconUrl, getAntdLocale } from '../../utils';
import { withFormField } from '../../hocs/withFormField';

import './AutoComplete.scss';

const classPrefix = 'lex-autocomplete';

export const AutoComplete: React.FC<AutoCompleteProps> = ({
  id,
  style,
  className,
  autoCompleteRef,
  autoCompleteClassName,
  variant,
  disabled,
  options,
  optionLabelProp = 'value',
  notFoundContent = 'No results found',
  open,
  onFocus,
  onChange,
  onSelect,
  onBlur,
  onClear,
  onSearch,
  searchLoadingMessage = 'LOADING',
  minSearchChars = 1,
  clearIcon,
  suffixIcon,
  menuItemSelectedIcon,
  defaultValue,
  value,
  searchValue,
  hiddenLabel,
  error,
  locale = navigator.language,
  ...rest
}) => {
  const isFirstMount = useFirstMountState();
  const componentRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [focused, setFocused] = useState(false);
  const [opts, setOpts] = useState(options || []);
  const [val, setVal] = useState<string | null>();
  const [selectedOption, setSelectedOption] = useState<Option>();
  const [searchVal, setSearchVal] = useState(searchValue);
  const [debouncedSearchVal, setDebouncedSearchVal] = useState(searchValue);
  const [searchPer, setSearchPer] = useState<number | undefined>();
  const [searchPage, setSearchPage] = useState<number>(1);
  const [searchTotal, setSearchTotal] = useState<number>(0);
  const [searchLoading, setSearchLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState<boolean>();

  const antdLocale = getAntdLocale(locale);

  const performedSearch =
    !searchLoading && (debouncedSearchVal?.length ?? 0) >= minSearchChars;

  const showNoDataContent =
    !!notFoundContent && !!debouncedSearchVal && (!onSearch || performedSearch);

  const enableSearchPagination = !disabled && !!searchPer;

  const clearSearch = () => {
    setSearchPer(undefined);
    setSearchPage(1);
    setSearchTotal(0);
  };

  const doBlurActions = () => {
    setFocused(false);
    setOpenMenu(false);

    if (onSearch) {
      setOpts([]);
      clearSearch();
    }
  };

  const getOptWithoutKey = (option: Option | undefined) => {
    if (!option) {
      return;
    }

    const { key, ...rest } = option;
    return rest;
  };

  const setListScrollLocation = (location: 'top' | 'bottom') =>
    setTimeout(() => {
      const list = document.querySelector('.rc-virtual-list-holder');
      if (list) {
        list.scrollTop = location === 'top' ? 0 : list.scrollHeight;
      }
    }, 200);

  const performSearch = useCallback(() => {
    // Only proceed when there is a search function
    if (!onSearch) {
      return;
    }

    if ((debouncedSearchVal?.length ?? 0) < minSearchChars) {
      setSearchLoading(false);
      return;
    }

    // Case where the user has selected a value, then blurred, then focused
    if (
      selectedOption &&
      debouncedSearchVal === val &&
      opts.length &&
      searchPage === 1
    ) {
      setSearchLoading(false);
      return;
    }

    const isSearching = focused;
    const isPaging = enableSearchPagination;

    // Only proceed when the user is currently performing a search or paging results
    if (!isSearching && !isPaging) {
      setSearchLoading(false);
      return;
    }

    (async () => {
      try {
        setSearchLoading(true);

        const searchResult = await onSearch(debouncedSearchVal as string, {
          per: searchPer,
          page: searchPage,
        });

        if (Array.isArray(searchResult)) {
          // Result is an array of options
          setOpts(searchResult as Option[]);
        } else {
          // Result contains options as well as metadata
          const options = searchResult?.options || [];
          setOpts(options);

          setSearchPer(searchResult?.metadata?.pagination?.per);

          if (options.length) {
            setSearchTotal(searchResult?.metadata?.pagination?.totalCount || 0);
          } else {
            setSearchTotal(0);
            setSearchPage(1);
          }
        }
      } catch (err) {
        console.error(err);
        setOpts([]);
        clearSearch();
      } finally {
        setSearchLoading(false);
        setOpenMenu(true);
        setListScrollLocation('top');
      }
    })();
  }, [
    onSearch,
    focused,
    enableSearchPagination,
    searchPage,
    debouncedSearchVal,
    minSearchChars,
    val,
    opts,
  ]);

  const handleValueChange = (
    value: string | null | undefined,
    option: Option | undefined,
    handlers?: (Function | undefined)[],
  ) => {
    option = Object.keys(option ?? {}).length === 0 ? undefined : option;

    const optionValue = option?.[optionLabelProp] as string | undefined;
    const newVal = optionValue || value;

    if (onSearch) {
      // NOTE: For autocomplete, set searchVal to val
      setSearchVal(newVal || '');
    }

    setVal(newVal);
    setSelectedOption(getOptWithoutKey(option));

    handlers?.map((h) => h?.(newVal, option));
  };

  const findOptionValue = (opts: Option[], val: string) => {
    return opts.some((opt) => {
      if (opt.options?.length) {
        if (findOptionValue(opt.options, val)) {
          return true;
        }
      }
      if (opt[optionLabelProp] === val) {
        return true;
      }
      return false;
    });
  };

  const renderOptions = (opts: Option[]) => {
    return opts
      .sort((a, b) =>
        String(a.order).localeCompare(String(b.order), undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
      )
      .map(
        (
          { key, label, value, disabled, tooltip, children, options, ...rest },
          idx,
        ) => {
          const reactKey = `${label}|${value}|${idx}`;

          if (options?.length) {
            return (
              <AntdSelect.OptGroup key={reactKey} label={label}>
                {renderOptions(options)}
              </AntdSelect.OptGroup>
            );
          }

          return (
            <AntdAutoComplete.Option
              key={reactKey}
              label={label}
              value={value}
              disabled={disabled}
              {...Object.keys(rest).reduce(
                (acc, prop) => ({
                  ...acc,
                  [prop]:
                    toString.call(rest[prop]) === '[object Boolean]'
                      ? rest[prop]
                        ? 1
                        : 0
                      : rest[prop],
                }),
                {},
              )}
            >
              <Tooltip key={reactKey} title={tooltip} placement="topLeft">
                {children ?? label}
              </Tooltip>
            </AntdAutoComplete.Option>
          );
        },
      );
  };

  useEffect(() => {
    if (!componentRef.current) {
      return;
    }

    // Focus when there is a click inside the menu or the component, otherwise blur
    const listener = (e: Event) => {
      const isControlClick =
        componentRef.current?.contains(e.target as Node) ||
        dropdownRef.current?.contains(e.target as Node);

      if (isControlClick && !focused) {
        // Trigger the focus
        setFocused(true);
        setListScrollLocation('top');
        onFocus?.(e as any);
        return;
      }

      if (!isControlClick && focused) {
        // Trigger the blur
        doBlurActions();
        onBlur?.(e as any);
        return;
      }
    };

    document.body.addEventListener('click', listener, true);

    return () => {
      document.body.removeEventListener('click', listener, true);
    };
  }, [
    componentRef.current,
    dropdownRef.current,
    id,
    focused,
    onFocus,
    onBlur,
    onSearch,
  ]);

  useEffect(() => {
    if (!componentRef.current) {
      return;
    }

    // Blur when there is a scroll event outside the menu or the component
    const listener = (e: Event) => {
      if (
        !componentRef.current?.contains(e.target as Node) &&
        !dropdownRef.current?.contains(e.target as Node)
      ) {
        // Trigger the blur
        doBlurActions();
        onBlur?.(e as any);
      }
    };

    if (openMenu) {
      window.addEventListener('scroll', listener, true);
    } else {
      window.removeEventListener('scroll', listener, true);
    }

    return () => {
      window.removeEventListener('scroll', listener, true);
    };
  }, [componentRef.current, dropdownRef.current, openMenu, onSearch]);

  useEffect(() => {
    if (openMenu === open) {
      return;
    }

    // New prop value passed in
    setOpenMenu(open);
  }, [open]);

  useEffect(() => {
    // If new value or default value prop is passed in
    setVal(isFirstMount ? value ?? defaultValue : value);
  }, [value, defaultValue]);

  useEffect(() => {
    if (onSearch || !val) {
      return;
    }

    const newOpts = [...opts];
    const match = findOptionValue(newOpts, val);

    if (match) {
      return;
    }

    // Add when no match is found
    if (selectedOption?.label && selectedOption?.value) {
      newOpts.push(selectedOption);
    } else {
      newOpts.push({ label: val, value: val });
    }

    setOpts(newOpts);
    setListScrollLocation('top');
  }, [onSearch, val, opts]);

  useEffect(() => {
    // If new options prop value is passed in
    const newOpts = [...(options || [])];

    // For search, add existing options when re-rendering
    if (onSearch && !isFirstMount && !newOpts.length && opts.length) {
      newOpts.push(...opts);
    }

    setOpts(newOpts);
    setListScrollLocation('top');
  }, [onSearch, options]);

  useEffect(() => {
    if (!val) {
      setSelectedOption(undefined);
      return;
    }

    if (searchLoading) {
      return;
    }

    const selectedOpt = opts.find((o) => o.value === val);

    setSelectedOption(getOptWithoutKey(selectedOpt));
  }, [opts]);

  useEffect(() => {
    // If new onSearch prop value is passed in
    clearSearch();
  }, [onSearch]);

  useEffect(() => {
    if (onSearch) {
      setSearchLoading((searchVal?.length ?? 0) >= minSearchChars);
    }
  }, [searchVal, minSearchChars]);

  useDebounce(
    () => {
      setDebouncedSearchVal(searchVal);
    },
    400,
    [searchVal],
  );

  useEffect(() => {
    // Reset page if search value has changed
    setSearchPage(1);
  }, [debouncedSearchVal]);

  useEffect(() => {
    // Perform search if page or search value has changed
    performSearch();
  }, [searchPage, debouncedSearchVal]);

  useEffect(() => {
    // Perform search if focus has changed
    // (and there's a search value without options)
    if (focused && onSearch && debouncedSearchVal && !opts.length) {
      performSearch();
    }
  }, [focused]);

  const searchPaginationContainer = enableSearchPagination &&
    searchTotal > 0 && (
      <div className={cn(`${classPrefix}__dropdown-pagination-container`)}>
        <Pagination
          className={cn(`${classPrefix}__dropdown-pagination`)}
          data-testid="autocomplete-search-pagination"
          defaultPageSize={searchPer}
          current={searchPage}
          onChange={setSearchPage}
          total={searchTotal}
        />
      </div>
    );

  const noDataContent = showNoDataContent && (
    <div className={cn(`${classPrefix}__no-content`)}>{notFoundContent}</div>
  );

  return (
    <div
      data-testid="autocomplete"
      className={cn(
        classPrefix,
        !notFoundContent && `${classPrefix}--hide-empty`,
        className,
      )}
      style={style}
      ref={componentRef}
    >
      {hiddenLabel && (
        <label className="screen-reader" htmlFor={id}>
          {hiddenLabel}
        </label>
      )}
      <Spinner
        spinning={searchLoading}
        message={searchLoadingMessage}
        className={cn(`${classPrefix}__spinner`)}
      >
        <AntdConfigProvider locale={antdLocale}>
          <AntdAutoComplete
            id={id}
            ref={autoCompleteRef}
            notFoundContent={noDataContent}
            virtual
            data-testid="autocomplete-autocomplete"
            allowClear
            clearIcon={
              clearIcon || (
                <div className={cn(`${classPrefix}__clear`)}>
                  <Image src={getIconUrl('clear')} />
                </div>
              )
            }
            suffixIcon={
              suffixIcon || (
                <div className={cn(`${classPrefix}__arrow`)}>
                  <Image src={getIconUrl('expand_more')} />
                </div>
              )
            }
            menuItemSelectedIcon={
              menuItemSelectedIcon || (
                <div className={cn(`${classPrefix}__check`)}>
                  <Image src={getIconUrl('check')} />
                </div>
              )
            }
            className={cn(
              `${classPrefix}__autocomplete`,
              variant && `${classPrefix}__autocomplete--${variant}`,
              error && `${classPrefix}__autocomplete--error`,
              disabled && `${classPrefix}__autocomplete--disabled`,
              focused && `${classPrefix}__autocomplete--focused`,
              autoCompleteClassName,
            )}
            disabled={disabled}
            showSearch
            showArrow={!onSearch}
            defaultActiveFirstOption={false}
            dropdownRender={(menu) => {
              return (
                <div
                  className={cn(
                    `${classPrefix}__dropdown`,
                    searchPaginationContainer &&
                      `${classPrefix}__dropdown--pagination`,
                  )}
                  ref={dropdownRef}
                >
                  <Spinner
                    size="small"
                    spinning={searchLoading}
                    className={cn(`${classPrefix}__dropdown-spinner`)}
                  >
                    <>
                      {menu}
                      {searchPaginationContainer}
                    </>
                  </Spinner>
                </div>
              );
            }}
            open={openMenu}
            value={val}
            searchValue={searchVal}
            onChange={(value, option) =>
              handleValueChange(value, option as Option, [onChange])
            }
            onSelect={(value, option) =>
              handleValueChange(value, option as Option, [onChange, onSelect])
            }
            onSearch={(value) => {
              if (onSearch) {
                setSearchLoading(true);
              }

              setSearchVal(value);
            }}
            onClear={() => {
              setSearchVal('');

              if (onSearch) {
                setOpts([]);
                clearSearch();
              }

              onClear?.();
            }}
            onFocus={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onBlur={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDropdownVisibleChange={(visible) => {
              if (onSearch) {
                if (searchLoading && !visible) {
                  // If menu was open, keep it open and focused while searching
                  setOpenMenu(true);
                  setFocused(true);
                  return;
                }

                if (searchVal && !focused) {
                  // If there is a search value but it is unfocused, hide the menu
                  setOpenMenu(false);
                  return;
                }
              }

              setOpenMenu(visible);

              if (visible) {
                // Always focused while visible
                setFocused(true);
              }
            }}
            filterOption={
              onSearch
                ? false
                : (searchValue, option) => {
                    const [label, value] = option?.key?.toString()?.split('|');
                    return (
                      label.toLowerCase().includes(searchValue.toLowerCase()) ||
                      value.toLowerCase().includes(searchValue.toLowerCase())
                    );
                  }
            }
            {...rest}
          >
            {renderOptions(opts)}
          </AntdAutoComplete>
        </AntdConfigProvider>
      </Spinner>
    </div>
  );
};

export default AutoComplete;

export const AutoCompleteFormField = withFormField(AutoComplete);
