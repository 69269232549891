import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFirstMountState } from 'react-use';
import cn from 'classnames';

import {
  CurrencyPickerProps,
  CurrencyPickerData,
  ExchangeRateMap,
} from './CurrencyPicker.types';
import { CurrencyBox } from '../CurrencyBox';
import { Image } from '../Image';
import { Popover } from '../Popover';
import { Select } from '../Select';
import { withFormField } from '../../hocs/withFormField';
import { getIconUrl, numberToCurrencyFormat } from '../../utils';

import './CurrencyPicker.scss';

const defConversionIconSrc = getIconUrl('currency_exchange');

export const CurrencyPicker: React.FC<CurrencyPickerProps> = ({
  id,
  style,
  className,
  variant,
  options = [],
  onChange,
  onCurrencyCodeChange,
  onValueChange,
  onExchangeDataChange,
  defaultValue,
  value,
  maximumFractionDigits,
  useGrouping,
  locale = navigator.language,
  convertedValueDisplay,
  convertedValueNumber,
  defaultCurrencyCode = 'usd',
  currencyCode,
  exchangeCurrencyCode,
  exchangeRate,
  disabled,
  currencyDisabled = false,
  conversionIconSrc,
  showConversionIcon,
  hiddenLabel,
  error,
  ...rest
}) => {
  if (!conversionIconSrc) conversionIconSrc = defConversionIconSrc;

  currencyCode = currencyCode || defaultCurrencyCode;
  exchangeCurrencyCode = exchangeCurrencyCode || defaultCurrencyCode;
  exchangeRate = exchangeRate || 1;

  const isFirstMount = useFirstMountState();
  const inputRef = useRef<HTMLInputElement>(null);
  const [pickerData, setPickerData] = useState<CurrencyPickerData>({});

  const formattedOpts = useMemo(
    () =>
      options
        .map((o) => ({
          ...o,
          label: o.label?.toUpperCase(),
          abbr: o.abbr?.toUpperCase(),
        }))
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    [options],
  );

  const allExchangeRates = useMemo<ExchangeRateMap>(
    () =>
      formattedOpts.reduce((acc, option) => {
        if (option.value && option.exchange_rate) {
          acc[option.value] = option.exchange_rate as number;
        }
        return acc;
      }, {} as ExchangeRateMap),
    [formattedOpts],
  );

  const getLatestPickerData = useCallback(
    ({ value, currencyCode, exchangeCurrencyCode }: CurrencyPickerData) => {
      // Get new data, fallback to defaults
      const newCurrCode = currencyCode || defaultCurrencyCode;
      const newExchCurrCode = exchangeCurrencyCode || defaultCurrencyCode;
      const newExchRate = allExchangeRates[newCurrCode] || exchangeRate || 1;
      const newExchValue = !value ? value : (1 / newExchRate) * value;
      const strExchValue = numberToCurrencyFormat(newExchValue, {
        locale,
        currencyCode: exchangeCurrencyCode,
        maximumFractionDigits,
        useGrouping,
      });

      return {
        value,
        currencyCode: newCurrCode,
        exchangeCurrencyCode: newExchCurrCode,
        exchangeRate: newExchRate,
        convertedValueDisplay: strExchValue,
        convertedValueNumber: newExchValue,
      } as CurrencyPickerData;
    },
    [
      defaultCurrencyCode,
      exchangeRate,
      JSON.stringify(allExchangeRates),
      locale,
      maximumFractionDigits,
      useGrouping,
    ],
  );

  useEffect(() => {
    // Update picker data when new props are passed in
    setPickerData(
      getLatestPickerData({
        ...pickerData,
        value: isFirstMount ? value ?? defaultValue : value,
        currencyCode,
        exchangeCurrencyCode,
        exchangeRate,
        convertedValueDisplay,
        convertedValueNumber,
      }),
    );
  }, [
    value,
    defaultValue,
    currencyCode,
    exchangeCurrencyCode,
    exchangeRate,
    convertedValueDisplay,
    convertedValueNumber,
  ]);

  const {
    currencyCode: currentCurrencyCode,
    value: currentValue,
    exchangeCurrencyCode: currentExchangeCurrencyCode,
    exchangeRate: currentExchangeRate,
    convertedValueDisplay: currentConvertedValueDisplay,
    convertedValueNumber: currentConvertedValue,
  } = pickerData;

  useEffect(() => {
    if (onExchangeDataChange) {
      // If exists, fire exchange data change event
      onExchangeDataChange({
        exchangeCurrencyCode: currentExchangeCurrencyCode,
        exchangeRate: currentExchangeRate,
        convertedValueDisplay: currentConvertedValueDisplay,
        convertedValueNumber: currentConvertedValue,
      });
      return;
    }

    // Otherwise, fire change event
    onChange?.(pickerData);
  }, [
    currentExchangeCurrencyCode,
    currentExchangeRate,
    currentConvertedValueDisplay,
    currentConvertedValue,
  ]);

  const handleCurrencyCodeChange = (currencyCode?: string) => {
    // Fire currency code change event
    const newPickerData = getLatestPickerData({
      ...pickerData,
      currencyCode,
    });

    setPickerData(newPickerData);
    onCurrencyCodeChange?.(currencyCode);

    // Fire change event
    onChange?.(newPickerData);
  };

  const handleValueChange = (value?: number) => {
    // Fire value change event
    const newPickerData = getLatestPickerData({
      ...pickerData,
      value,
    });

    setPickerData(newPickerData);
    onValueChange?.(value);

    // Fire change event
    onChange?.(newPickerData);
  };

  const isDefaultCurrency =
    currentCurrencyCode?.toLowerCase() === defaultCurrencyCode.toLowerCase();

  const shouldShowConvertedValue =
    !isDefaultCurrency && !!currentConvertedValueDisplay;

  let convertedValueElement;

  if (showConversionIcon && shouldShowConvertedValue) {
    convertedValueElement = (
      <div
        data-testid="currency-picker-exchange-button"
        className={cn('lex-currency-picker__exchange')}
      >
        <Popover
          variant={variant}
          placement="left"
          trigger={['click', 'hover']}
          arrowPointAtCenter
          content={
            <span data-testid="currency-picker-exchange-label">
              =&nbsp;{currentConvertedValueDisplay}
            </span>
          }
        >
          <div className={cn('lex-currency-picker__exchange-icon')}>
            <Image src={conversionIconSrc} />
          </div>
        </Popover>
      </div>
    );
  } else if (!showConversionIcon) {
    convertedValueElement = (
      <span
        data-testid="currency-picker-exchange-label"
        className={cn(
          'lex-currency-picker__exchange',
          !shouldShowConvertedValue && `lex-currency-picker__exchange--hidden`,
          !disabled && `lex-currency-picker__exchange--min-width`,
        )}
      >
        =&nbsp;{currentConvertedValueDisplay}
      </span>
    );
  }

  return (
    <div
      data-testid="currency-picker"
      className={cn('lex-currency-picker', className)}
      style={style}
    >
      {hiddenLabel && (
        <label className="screen-reader" htmlFor={id}>
          {hiddenLabel}
        </label>
      )}
      <Select
        variant={variant}
        onChange={handleCurrencyCodeChange}
        options={formattedOpts}
        value={currentCurrencyCode}
        disabled={disabled || currencyDisabled}
        error={error}
        allowClear={false}
        data-testid="currency-select-input"
      />
      <CurrencyBox
        ref={inputRef}
        variant={variant}
        onChange={handleValueChange}
        locale={locale}
        currencyCode={currentCurrencyCode}
        value={currentValue}
        currencyDisplay="narrowSymbol"
        maximumFractionDigits={maximumFractionDigits}
        useGrouping={useGrouping}
        disabled={disabled}
        error={error}
        {...rest}
      />
      {convertedValueElement}
    </div>
  );
};

export default CurrencyPicker;

export const CurrencyPickerFormField = withFormField(CurrencyPicker);
