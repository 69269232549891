import React, { useLayoutEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { TileProps } from './Tile.types';
import { Image } from '../Image';
import { Truncate } from '../Truncate';

import './Tile.scss';

export const Tile: React.FC<TileProps> = ({
  id,
  style,
  className,
  width,
  height,
  iconUrl,
  iconWidth,
  iconHeight,
  truncate = true,
  truncateLines,
  label,
  ...rest
}) => {
  if (
    truncate &&
    width === undefined &&
    height === undefined &&
    truncateLines === undefined
  ) {
    // set default value to 4
    truncateLines = 4;
  }

  const tileLabelContainerRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const [addIconMargin, setAddIconMargin] = useState(false);

  useLayoutEffect(() => {
    const labelSpan =
      tileLabelContainerRef.current?.querySelector('.lex-truncate span');

    const containerHeight = tileLabelContainerRef.current?.clientHeight ?? 0;
    const addMargin =
      containerHeight > 0 &&
      (labelSpan?.clientHeight ?? 0) / containerHeight >= 0.8;

    setAddIconMargin(addMargin);
  }, [tileLabelContainerRef.current]);

  return (
    <div
      id={id}
      data-testid="tile"
      className={cn('lex-tile', !truncate && 'lex-tile--overflow', className)}
      style={{ width, height, ...style }}
      title={isTruncated ? label : ''}
      {...rest}
    >
      <label data-testid="tile-label" className="screen-reader" htmlFor={id}>
        {label}
      </label>
      {iconUrl && (
        <div
          className={cn(
            'lex-tile__icon-container',
            addIconMargin && 'margin-top--xxxs',
            addIconMargin && 'margin-bottom--xxxs',
          )}
        >
          <Image
            data-testid="tile-icon"
            className={cn('lex-tile__icon')}
            src={iconUrl}
            width={iconWidth}
            height={iconHeight}
          />
        </div>
      )}
      <div
        className={cn('lex-tile__label-container')}
        ref={tileLabelContainerRef}
      >
        <Truncate
          lines={truncate ? truncateLines : 100}
          onTruncate={(truncated) => setIsTruncated(truncated)}
        >
          <span className={cn('lex-tile__label')}>{label}</span>
        </Truncate>
      </div>
    </div>
  );
};

export default Tile;
