import React from 'react';
import cn from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import generateRangePicker from 'antd/lib/date-picker/generatePicker/generateRangePicker';
import AntdConfigProvider from 'antd/lib/config-provider';

import { DateRangePickerProps } from './DateRangePicker.types';
import Image from '../Image';
import { withFormField } from '../../hocs/withFormField';
import {
  getAntdLocale,
  getIconUrl,
  is24HourTimeLocale,
  loadDayjsLocale,
} from '../../utils';

import './DateRangePicker.scss';

dayjs.extend(utc);

const AntRangePicker = generateRangePicker<Dayjs>(dayjsGenerateConfig);

const DATE_ISO = 'YYYY-MM-DD';
const DATETIME_SEP = 'T';
const TIME_ISO = 'HH:mm';
const DATETIME_ISO_NO_SEC = `${DATE_ISO}${DATETIME_SEP}${TIME_ISO}[Z]`;
const TIME_DISP_12HRS = 'h:mm A';
const TIME_DISP_24HRS = 'H:mm';
const DATE_DISP = 'MMM D, YYYY';
const DATETIME_DISP_12HRS = `${DATE_DISP}, ${TIME_DISP_12HRS}`;
const DATETIME_DISP_24HRS = `${DATE_DISP}, ${TIME_DISP_24HRS}`;

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  id,
  className,
  variant,
  disabled,
  placeholder,
  format,
  defaultValue,
  value,
  hiddenLabel,
  error,
  onChange,
  showTime,
  timeProps,
  locale = navigator.language,
  ...rest
}) => {
  const is24Hrs = is24HourTimeLocale(locale);
  const antdLocale = getAntdLocale(locale);
  loadDayjsLocale(locale);

  let timeInfo: typeof timeProps | undefined;

  if (showTime) {
    timeInfo =
      timeProps ||
      (is24Hrs ? { format: TIME_DISP_24HRS } : { format: TIME_DISP_12HRS });
  }

  const dateFormat: DateRangePickerProps['format'] =
    format ||
    ((value) =>
      showTime
        ? value
            .locale(locale)
            .format(is24Hrs ? DATETIME_DISP_24HRS : DATETIME_DISP_12HRS)
        : value.locale(locale).format(DATE_DISP));

  const getValues = (values?: RangeValue<string | Dayjs>) => {
    if (!values) return values;
    return [
      values[0] ? dayjs(values[0]) : null,
      values[1] ? dayjs(values[1]) : null,
    ] as RangeValue<Dayjs>;
  };

  return (
    <>
      {hiddenLabel && (
        <label className="screen-reader" htmlFor={id}>
          {hiddenLabel}
        </label>
      )}
      <AntdConfigProvider locale={antdLocale}>
        <AntRangePicker
          id={id}
          data-testid="date-range-picker"
          className={cn(
            'lex-date-range-picker',
            variant && `lex-date-range-picker--${variant}`,
            error && 'lex-date-range-picker--error',
            disabled && 'lex-date-range-picker--disabled',
            className,
          )}
          getPopupContainer={(triggerNode) =>
            triggerNode.parentElement ?? triggerNode
          }
          disabled={disabled}
          placeholder={placeholder ?? ['', '']}
          format={dateFormat}
          showTime={timeInfo}
          defaultValue={getValues(defaultValue)}
          value={getValues(value)}
          separator={
            <Image
              className={cn('lex-date-range-picker__separator-icon')}
              src={getIconUrl('arrow_right_alt')}
            />
          }
          suffixIcon={
            <Image
              className={cn('lex-date-range-picker__picker-icon')}
              src={getIconUrl('calendar_today')}
            />
          }
          clearIcon={
            <Image
              className={cn('lex-date-range-picker__clear-icon')}
              src={getIconUrl('clear')}
            />
          }
          onChange={(value) => {
            if (!value) {
              onChange?.(['', ''], value);
              return;
            }

            const isoValue = value.map((v) =>
              showTime
                ? dayjs(v).utc().format(DATETIME_ISO_NO_SEC)
                : dayjs(v).format(DATE_ISO),
            );

            onChange?.(isoValue, value);
          }}
          {...rest}
        />
      </AntdConfigProvider>
    </>
  );
};

export default DateRangePicker;

export const DateRangePickerFormField = withFormField(DateRangePicker);
