import { isDate, isEmpty, isNumber } from 'lodash';

export const escapeRegEx = (value: string) => {
  return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const isBlank = (value: any) => {
  if (isNumber(value) || isDate(value)) {
    return false;
  }
  return isEmpty(value);
};

export const toKebabCase = (value: string) => {
  return value
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();
};
