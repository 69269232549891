import React, { useState } from 'react';
import cn from 'classnames';

import { ButtonProps } from './Button.types';
import { Popconfirm } from '../Popconfirm';
import { Tooltip } from '../Tooltip';

import './Button.scss';

const BaseButton: React.FC<ButtonProps> = ({
  className,
  variant = 'primary',
  rounded = true,
  disabled,
  children,
  ...rest
}) => {
  return (
    <button
      data-testid="button"
      disabled={disabled}
      {...rest}
      className={cn(
        'lex-button',
        variant && `lex-button--${variant}`,
        rounded && 'lex-button--rounded',
        disabled && 'lex-button--disabled',
        className,
      )}
    >
      {children}
    </button>
  );
};

export const Button: React.FC<ButtonProps> = ({
  popconfirmProps,
  tooltipProps,
  ...rest
}) => {
  const [isPopconfirmOpen, setIsPopconfirmOpen] = useState(
    popconfirmProps?.open || false,
  );
  if (popconfirmProps) {
    return (
      <Popconfirm
        onConfirm={(e) => {
          setIsPopconfirmOpen(false);
          setTimeout(() => {
            rest.onClick &&
              rest.onClick(
                e as React.MouseEvent<HTMLButtonElement, MouseEvent>,
              );
          }, 0);
        }}
        getPopupContainer={(triggerNode) =>
          triggerNode.parentElement ?? triggerNode
        }
        onCancel={() => {
          setIsPopconfirmOpen(false);
        }}
        {...popconfirmProps}
        open={isPopconfirmOpen}
      >
        <BaseButton
          {...rest}
          onClick={() => {
            setIsPopconfirmOpen(true);
          }}
        />
      </Popconfirm>
    );
  }

  if (tooltipProps) {
    return (
      <Tooltip {...tooltipProps}>
        <span className={cn('lex-button--tooltip')}>
          <BaseButton {...rest} />
        </span>
      </Tooltip>
    );
  }

  return <BaseButton {...rest} />;
};

export default Button;
