import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import AntSlider from 'antd/lib/slider';
import AntdConfigProvider from 'antd/lib/config-provider';
import { useFirstMountState } from 'react-use';

import { SliderProps, SliderTrackRgb } from './Slider.types';
import { withFormField } from '../../hocs/withFormField';
import { getAntdLocale } from '../../utils';

import './Slider.scss';

export const RISK_SCORE_NUMBER_PROPS = {
  min: 0,
  max: 5,
  step: 0.1,
};

export const RISK_SCORE_SLIDER_PROPS: Partial<SliderProps> = {
  ...RISK_SCORE_NUMBER_PROPS,
  marks: {
    0: '0',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
  },
  trackColors: [
    { r: 0, g: 133, b: 53 },
    { r: 255, g: 169, b: 0 },
    { r: 210, g: 59, b: 90 },
  ],
  trackColorTransitionSteps: [0, 2.5, 5],
};

const lerp = (start: number, end: number, amt: number) => {
  return (1 - amt) * start + amt * end;
};

const rgbToCss = ({ r, g, b }: SliderTrackRgb) => {
  return `rgb(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)})`;
};

const getTrackRgb = (
  trackColors: SliderTrackRgb[],
  transitionSteps: number[],
  amt: number,
) => {
  if (amt <= transitionSteps[0]) {
    return trackColors[0];
  }

  if (amt >= transitionSteps[transitionSteps.length - 1]) {
    return trackColors[trackColors.length - 1];
  }

  let i: number;

  for (i = 0; amt > transitionSteps[i]; i++);

  const from = trackColors[i - 1];
  const to = trackColors[i];

  amt =
    (amt - transitionSteps[i - 1]) /
    (transitionSteps[i] - transitionSteps[i - 1]);

  return {
    r: lerp(from.r, to.r, amt),
    g: lerp(from.g, to.g, amt),
    b: lerp(from.b, to.b, amt),
  } as SliderTrackRgb;
};

export const Slider: FC<SliderProps> = ({
  id,
  className,
  style,
  variant = 'primary',
  disabled,
  onChange,
  min,
  max,
  defaultValue,
  value,
  caption,
  trackColors = [],
  trackColorTransitionSteps = [],
  locale = navigator.language,
  ...rest
}) => {
  const isFirstMount = useFirstMountState();
  const [val, setVal] = useState<number>();
  const sliderRef = useRef<HTMLDivElement>(null);

  const antdLocale = getAntdLocale(locale);

  useEffect(() => {
    // If new value or default value prop is passed in
    setVal(isFirstMount ? value ?? defaultValue : value);
  }, [value, defaultValue]);

  const setTrackColor = useCallback(
    (value: number) => {
      const track = sliderRef?.current?.querySelector(
        '.ant-slider-track',
      ) as HTMLElement;

      if (!track || min === undefined || max === undefined) {
        return;
      }

      const amt = lerp(min, max, value / max);
      const trackRgb = getTrackRgb(trackColors, trackColorTransitionSteps, amt);
      const cssColor = rgbToCss(trackRgb);

      track.style.setProperty('background-color', cssColor, 'important');
    },
    [sliderRef, min, max, trackColors, trackColorTransitionSteps],
  );

  useLayoutEffect(() => {
    if (!trackColors.length) {
      return;
    }

    if (val === undefined) {
      return;
    }

    setTrackColor(val);
  }, [trackColors, val]);

  return (
    <div
      ref={sliderRef}
      data-testid="slider"
      className={cn(
        'lex-slider',
        variant && `lex-slider--${variant}`,
        disabled && 'lex-slider--disabled',
        className,
      )}
      style={style}
    >
      {caption && (
        <span
          data-testid="slider-caption"
          className={cn('lex-slider__caption')}
        >
          {caption}
        </span>
      )}
      <AntdConfigProvider locale={antdLocale}>
        <AntSlider
          id={id}
          data-testid="slider-slider"
          className={cn('lex-slider__slider')}
          range={false}
          min={min}
          max={max}
          value={val}
          disabled={disabled}
          onChange={(value) => {
            setVal(value);
            onChange?.(value);
          }}
          {...rest}
        />
      </AntdConfigProvider>
    </div>
  );
};

export default Slider;

export const SliderFormField = withFormField(Slider);
