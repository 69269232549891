import { Locale as AntdLocale } from 'antd/lib/locale-provider';
import antd_ar_eg from 'antd/lib/locale/ar_EG';
import antd_az_az from 'antd/lib/locale/az_AZ';
import antd_bg_bg from 'antd/lib/locale/bg_BG';
import antd_bn_bd from 'antd/lib/locale/bn_BD';
import antd_by_by from 'antd/lib/locale/by_BY';
import antd_ca_es from 'antd/lib/locale/ca_ES';
import antd_cs_cz from 'antd/lib/locale/cs_CZ';
import antd_da_dk from 'antd/lib/locale/da_DK';
import antd_de_de from 'antd/lib/locale/de_DE';
import antd_el_gr from 'antd/lib/locale/el_GR';
import antd_en_gb from 'antd/lib/locale/en_GB';
import antd_en_us from 'antd/lib/locale/en_US';
import antd_es_es from 'antd/lib/locale/es_ES';
import antd_et_ee from 'antd/lib/locale/et_EE';
import antd_fa_ir from 'antd/lib/locale/fa_IR';
import antd_fi_fi from 'antd/lib/locale/fi_FI';
import antd_fr_be from 'antd/lib/locale/fr_BE';
import antd_fr_ca from 'antd/lib/locale/fr_CA';
import antd_fr_fr from 'antd/lib/locale/fr_FR';
import antd_ga_ie from 'antd/lib/locale/ga_IE';
import antd_gl_es from 'antd/lib/locale/gl_ES';
import antd_he_il from 'antd/lib/locale/he_IL';
import antd_hi_in from 'antd/lib/locale/hi_IN';
import antd_hr_hr from 'antd/lib/locale/hr_HR';
import antd_hu_hu from 'antd/lib/locale/hu_HU';
import antd_hy_am from 'antd/lib/locale/hy_AM';
import antd_id_id from 'antd/lib/locale/id_ID';
import antd_is_is from 'antd/lib/locale/is_IS';
import antd_it_it from 'antd/lib/locale/it_IT';
import antd_ja_jp from 'antd/lib/locale/ja_JP';
import antd_ka_ge from 'antd/lib/locale/ka_GE';
import antd_kk_kz from 'antd/lib/locale/kk_KZ';
import antd_kmr_iq from 'antd/lib/locale/kmr_IQ';
import antd_km_kh from 'antd/lib/locale/km_KH';
import antd_kn_in from 'antd/lib/locale/kn_IN';
import antd_ko_kr from 'antd/lib/locale/ko_KR';
import antd_ku_iq from 'antd/lib/locale/ku_IQ';
import antd_lt_lt from 'antd/lib/locale/lt_LT';
import antd_lv_lv from 'antd/lib/locale/lv_LV';
import antd_mk_mk from 'antd/lib/locale/mk_MK';
import antd_ml_in from 'antd/lib/locale/ml_IN';
import antd_mn_mn from 'antd/lib/locale/mn_MN';
import antd_ms_my from 'antd/lib/locale/ms_MY';
import antd_nb_no from 'antd/lib/locale/nb_NO';
import antd_ne_np from 'antd/lib/locale/ne_NP';
import antd_nl_be from 'antd/lib/locale/nl_BE';
import antd_nl_nl from 'antd/lib/locale/nl_NL';
import antd_pl_pl from 'antd/lib/locale/pl_PL';
import antd_pt_br from 'antd/lib/locale/pt_BR';
import antd_pt_pt from 'antd/lib/locale/pt_PT';
import antd_ro_ro from 'antd/lib/locale/ro_RO';
import antd_ru_ru from 'antd/lib/locale/ru_RU';
import antd_si_lk from 'antd/lib/locale/si_LK';
import antd_sk_sk from 'antd/lib/locale/sk_SK';
import antd_sl_si from 'antd/lib/locale/sl_SI';
import antd_sr_rs from 'antd/lib/locale/sr_RS';
import antd_sv_se from 'antd/lib/locale/sv_SE';
import antd_ta_in from 'antd/lib/locale/ta_IN';
import antd_th_th from 'antd/lib/locale/th_TH';
import antd_tk_tk from 'antd/lib/locale/tk_TK';
import antd_tr_tr from 'antd/lib/locale/tr_TR';
import antd_uk_ua from 'antd/lib/locale/uk_UA';
import antd_ur_pk from 'antd/lib/locale/ur_PK';
import antd_uz_uz from 'antd/lib/locale/uz_UZ';
import antd_vi_vn from 'antd/lib/locale/vi_VN';
import antd_zh_cn from 'antd/lib/locale/zh_CN';
import antd_zh_hk from 'antd/lib/locale/zh_HK';
import antd_zh_tw from 'antd/lib/locale/zh_TW';
import dayjs from 'dayjs';
import dayjs_af from 'dayjs/locale/af';
import dayjs_am from 'dayjs/locale/am';
import dayjs_ar_dz from 'dayjs/locale/ar-dz';
import dayjs_ar_iq from 'dayjs/locale/ar-iq';
import dayjs_ar_kw from 'dayjs/locale/ar-kw';
import dayjs_ar_ly from 'dayjs/locale/ar-ly';
import dayjs_ar_ma from 'dayjs/locale/ar-ma';
import dayjs_ar_sa from 'dayjs/locale/ar-sa';
import dayjs_ar_tn from 'dayjs/locale/ar-tn';
import dayjs_ar from 'dayjs/locale/ar';
import dayjs_az from 'dayjs/locale/az';
import dayjs_be from 'dayjs/locale/be';
import dayjs_bg from 'dayjs/locale/bg';
import dayjs_bi from 'dayjs/locale/bi';
import dayjs_bm from 'dayjs/locale/bm';
import dayjs_bn_bd from 'dayjs/locale/bn-bd';
import dayjs_bn from 'dayjs/locale/bn';
import dayjs_bo from 'dayjs/locale/bo';
import dayjs_br from 'dayjs/locale/br';
import dayjs_bs from 'dayjs/locale/bs';
import dayjs_ca from 'dayjs/locale/ca';
import dayjs_cs from 'dayjs/locale/cs';
import dayjs_cv from 'dayjs/locale/cv';
import dayjs_cy from 'dayjs/locale/cy';
import dayjs_da from 'dayjs/locale/da';
import dayjs_de_at from 'dayjs/locale/de-at';
import dayjs_de_ch from 'dayjs/locale/de-ch';
import dayjs_de from 'dayjs/locale/de';
import dayjs_dv from 'dayjs/locale/dv';
import dayjs_el from 'dayjs/locale/el';
import dayjs_en_au from 'dayjs/locale/en-au';
import dayjs_en_ca from 'dayjs/locale/en-ca';
import dayjs_en_gb from 'dayjs/locale/en-gb';
import dayjs_en_ie from 'dayjs/locale/en-ie';
import dayjs_en_il from 'dayjs/locale/en-il';
import dayjs_en_in from 'dayjs/locale/en-in';
import dayjs_en_nz from 'dayjs/locale/en-nz';
import dayjs_en_sg from 'dayjs/locale/en-sg';
import dayjs_en_tt from 'dayjs/locale/en-tt';
import dayjs_en from 'dayjs/locale/en';
import dayjs_eo from 'dayjs/locale/eo';
import dayjs_es_do from 'dayjs/locale/es-do';
import dayjs_es_mx from 'dayjs/locale/es-mx';
import dayjs_es_pr from 'dayjs/locale/es-pr';
import dayjs_es_us from 'dayjs/locale/es-us';
import dayjs_es from 'dayjs/locale/es';
import dayjs_et from 'dayjs/locale/et';
import dayjs_eu from 'dayjs/locale/eu';
import dayjs_fa from 'dayjs/locale/fa';
import dayjs_fi from 'dayjs/locale/fi';
import dayjs_fo from 'dayjs/locale/fo';
import dayjs_fr_ca from 'dayjs/locale/fr-ca';
import dayjs_fr_ch from 'dayjs/locale/fr-ch';
import dayjs_fr from 'dayjs/locale/fr';
import dayjs_fy from 'dayjs/locale/fy';
import dayjs_ga from 'dayjs/locale/ga';
import dayjs_gd from 'dayjs/locale/gd';
import dayjs_gl from 'dayjs/locale/gl';
import dayjs_gom_latn from 'dayjs/locale/gom-latn';
import dayjs_gu from 'dayjs/locale/gu';
import dayjs_he from 'dayjs/locale/he';
import dayjs_hi from 'dayjs/locale/hi';
import dayjs_hr from 'dayjs/locale/hr';
import dayjs_ht from 'dayjs/locale/ht';
import dayjs_hu from 'dayjs/locale/hu';
import dayjs_hy_am from 'dayjs/locale/hy-am';
import dayjs_id from 'dayjs/locale/id';
import dayjs_is from 'dayjs/locale/is';
import dayjs_it_ch from 'dayjs/locale/it-ch';
import dayjs_it from 'dayjs/locale/it';
import dayjs_ja from 'dayjs/locale/ja';
import dayjs_jv from 'dayjs/locale/jv';
import dayjs_ka from 'dayjs/locale/ka';
import dayjs_kk from 'dayjs/locale/kk';
import dayjs_km from 'dayjs/locale/km';
import dayjs_kn from 'dayjs/locale/kn';
import dayjs_ko from 'dayjs/locale/ko';
import dayjs_ku from 'dayjs/locale/ku';
import dayjs_ky from 'dayjs/locale/ky';
import dayjs_lb from 'dayjs/locale/lb';
import dayjs_lo from 'dayjs/locale/lo';
import dayjs_lt from 'dayjs/locale/lt';
import dayjs_lv from 'dayjs/locale/lv';
import dayjs_me from 'dayjs/locale/me';
import dayjs_mi from 'dayjs/locale/mi';
import dayjs_mk from 'dayjs/locale/mk';
import dayjs_ml from 'dayjs/locale/ml';
import dayjs_mn from 'dayjs/locale/mn';
import dayjs_mr from 'dayjs/locale/mr';
import dayjs_ms_my from 'dayjs/locale/ms-my';
import dayjs_ms from 'dayjs/locale/ms';
import dayjs_mt from 'dayjs/locale/mt';
import dayjs_my from 'dayjs/locale/my';
import dayjs_nb from 'dayjs/locale/nb';
import dayjs_ne from 'dayjs/locale/ne';
import dayjs_nl_be from 'dayjs/locale/nl-be';
import dayjs_nl from 'dayjs/locale/nl';
import dayjs_nn from 'dayjs/locale/nn';
import dayjs_oc_lnc from 'dayjs/locale/oc-lnc';
import dayjs_pa_in from 'dayjs/locale/pa-in';
import dayjs_pl from 'dayjs/locale/pl';
import dayjs_pt_br from 'dayjs/locale/pt-br';
import dayjs_pt from 'dayjs/locale/pt';
import dayjs_rn from 'dayjs/locale/rn';
import dayjs_ro from 'dayjs/locale/ro';
import dayjs_ru from 'dayjs/locale/ru';
import dayjs_rw from 'dayjs/locale/rw';
import dayjs_sd from 'dayjs/locale/sd';
import dayjs_se from 'dayjs/locale/se';
import dayjs_si from 'dayjs/locale/si';
import dayjs_sk from 'dayjs/locale/sk';
import dayjs_sl from 'dayjs/locale/sl';
import dayjs_sq from 'dayjs/locale/sq';
import dayjs_sr_cyrl from 'dayjs/locale/sr-cyrl';
import dayjs_sr from 'dayjs/locale/sr';
import dayjs_ss from 'dayjs/locale/ss';
import dayjs_sv_fi from 'dayjs/locale/sv-fi';
import dayjs_sv from 'dayjs/locale/sv';
import dayjs_sw from 'dayjs/locale/sw';
import dayjs_ta from 'dayjs/locale/ta';
import dayjs_te from 'dayjs/locale/te';
import dayjs_tet from 'dayjs/locale/tet';
import dayjs_tg from 'dayjs/locale/tg';
import dayjs_th from 'dayjs/locale/th';
import dayjs_tk from 'dayjs/locale/tk';
import dayjs_tl_ph from 'dayjs/locale/tl-ph';
import dayjs_tlh from 'dayjs/locale/tlh';
import dayjs_tr from 'dayjs/locale/tr';
import dayjs_tzl from 'dayjs/locale/tzl';
import dayjs_tzm_latn from 'dayjs/locale/tzm-latn';
import dayjs_tzm from 'dayjs/locale/tzm';
import dayjs_ug_cn from 'dayjs/locale/ug-cn';
import dayjs_uk from 'dayjs/locale/uk';
import dayjs_ur from 'dayjs/locale/ur';
import dayjs_uz_latn from 'dayjs/locale/uz-latn';
import dayjs_uz from 'dayjs/locale/uz';
import dayjs_vi from 'dayjs/locale/vi';
import dayjs_x_pseudo from 'dayjs/locale/x-pseudo';
import dayjs_yo from 'dayjs/locale/yo';
import dayjs_zh_cn from 'dayjs/locale/zh-cn';
import dayjs_zh_hk from 'dayjs/locale/zh-hk';
import dayjs_zh_tw from 'dayjs/locale/zh-tw';
import dayjs_zh from 'dayjs/locale/zh';

import { toKebabCase } from './strings';

export const AntdLocaleMap: Record<string, AntdLocale> = {
  ['ar-eg']: antd_ar_eg,
  ['az-az']: antd_az_az,
  ['bg-bg']: antd_bg_bg,
  ['bn-bd']: antd_bn_bd,
  ['by-by']: antd_by_by,
  ['ca-es']: antd_ca_es,
  ['cs-cz']: antd_cs_cz,
  ['da-dk']: antd_da_dk,
  ['de-de']: antd_de_de,
  ['el-gr']: antd_el_gr,
  ['en-gb']: antd_en_gb,
  ['en-us']: antd_en_us,
  ['es-es']: antd_es_es,
  ['et-ee']: antd_et_ee,
  ['fa-ir']: antd_fa_ir,
  ['fi-fi']: antd_fi_fi,
  ['fr-be']: antd_fr_be,
  ['fr-ca']: antd_fr_ca,
  ['fr-fr']: antd_fr_fr,
  ['ga-ie']: antd_ga_ie,
  ['gl-es']: antd_gl_es,
  ['he-il']: antd_he_il,
  ['hi-in']: antd_hi_in,
  ['hr-hr']: antd_hr_hr,
  ['hu-hu']: antd_hu_hu,
  ['hy-am']: antd_hy_am,
  ['id-id']: antd_id_id,
  ['is-is']: antd_is_is,
  ['it-it']: antd_it_it,
  ['ja-jp']: antd_ja_jp,
  ['ka-ge']: antd_ka_ge,
  ['kk-kz']: antd_kk_kz,
  ['kmr-iq']: antd_kmr_iq,
  ['km-kh']: antd_km_kh,
  ['kn-in']: antd_kn_in,
  ['ko-kr']: antd_ko_kr,
  ['ku-iq']: antd_ku_iq,
  ['lt-lt']: antd_lt_lt,
  ['lv-lv']: antd_lv_lv,
  ['mk-mk']: antd_mk_mk,
  ['ml-in']: antd_ml_in,
  ['mn-mn']: antd_mn_mn,
  ['ms-my']: antd_ms_my,
  ['nb-no']: antd_nb_no,
  ['ne-np']: antd_ne_np,
  ['nl-be']: antd_nl_be,
  ['nl-nl']: antd_nl_nl,
  ['pl-pl']: antd_pl_pl,
  ['pt-br']: antd_pt_br,
  ['pt-pt']: antd_pt_pt,
  ['ro-ro']: antd_ro_ro,
  ['ru-ru']: antd_ru_ru,
  ['si-lk']: antd_si_lk,
  ['sk-sk']: antd_sk_sk,
  ['sl-si']: antd_sl_si,
  ['sr-rs']: antd_sr_rs,
  ['sv-se']: antd_sv_se,
  ['ta-in']: antd_ta_in,
  ['th-th']: antd_th_th,
  ['tk-tk']: antd_tk_tk,
  ['tr-tr']: antd_tr_tr,
  ['uk-ua']: antd_uk_ua,
  ['ur-pk']: antd_ur_pk,
  ['uz-uz']: antd_uz_uz,
  ['vi-vn']: antd_vi_vn,
  ['zh-cn']: antd_zh_cn,
  ['zh-hk']: antd_zh_hk,
  ['zh-tw']: antd_zh_tw,
};

export const DayjsLocaleMap: Record<string, object> = {
  ['af']: dayjs_af,
  ['am']: dayjs_am,
  ['ar-dz']: dayjs_ar_dz,
  ['ar-iq']: dayjs_ar_iq,
  ['ar-kw']: dayjs_ar_kw,
  ['ar-ly']: dayjs_ar_ly,
  ['ar-ma']: dayjs_ar_ma,
  ['ar-sa']: dayjs_ar_sa,
  ['ar-tn']: dayjs_ar_tn,
  ['ar']: dayjs_ar,
  ['az']: dayjs_az,
  ['be']: dayjs_be,
  ['bg']: dayjs_bg,
  ['bi']: dayjs_bi,
  ['bm']: dayjs_bm,
  ['bn-bd']: dayjs_bn_bd,
  ['bn']: dayjs_bn,
  ['bo']: dayjs_bo,
  ['br']: dayjs_br,
  ['bs']: dayjs_bs,
  ['ca']: dayjs_ca,
  ['cs']: dayjs_cs,
  ['cv']: dayjs_cv,
  ['cy']: dayjs_cy,
  ['da']: dayjs_da,
  ['de-at']: dayjs_de_at,
  ['de-ch']: dayjs_de_ch,
  ['de']: dayjs_de,
  ['dv']: dayjs_dv,
  ['el']: dayjs_el,
  ['en-au']: dayjs_en_au,
  ['en-ca']: dayjs_en_ca,
  ['en-gb']: dayjs_en_gb,
  ['en-ie']: dayjs_en_ie,
  ['en-il']: dayjs_en_il,
  ['en-in']: dayjs_en_in,
  ['en-nz']: dayjs_en_nz,
  ['en-sg']: dayjs_en_sg,
  ['en-tt']: dayjs_en_tt,
  ['en']: dayjs_en,
  ['eo']: dayjs_eo,
  ['es-do']: dayjs_es_do,
  ['es-mx']: dayjs_es_mx,
  ['es-pr']: dayjs_es_pr,
  ['es-us']: dayjs_es_us,
  ['es']: dayjs_es,
  ['et']: dayjs_et,
  ['eu']: dayjs_eu,
  ['fa']: dayjs_fa,
  ['fi']: dayjs_fi,
  ['fo']: dayjs_fo,
  ['fr-ca']: dayjs_fr_ca,
  ['fr-ch']: dayjs_fr_ch,
  ['fr']: dayjs_fr,
  ['fy']: dayjs_fy,
  ['ga']: dayjs_ga,
  ['gd']: dayjs_gd,
  ['gl']: dayjs_gl,
  ['gom-latn']: dayjs_gom_latn,
  ['gu']: dayjs_gu,
  ['he']: dayjs_he,
  ['hi']: dayjs_hi,
  ['hr']: dayjs_hr,
  ['ht']: dayjs_ht,
  ['hu']: dayjs_hu,
  ['hy-am']: dayjs_hy_am,
  ['id']: dayjs_id,
  ['is']: dayjs_is,
  ['it-ch']: dayjs_it_ch,
  ['it']: dayjs_it,
  ['ja']: dayjs_ja,
  ['jv']: dayjs_jv,
  ['ka']: dayjs_ka,
  ['kk']: dayjs_kk,
  ['km']: dayjs_km,
  ['kn']: dayjs_kn,
  ['ko']: dayjs_ko,
  ['ku']: dayjs_ku,
  ['ky']: dayjs_ky,
  ['lb']: dayjs_lb,
  ['lo']: dayjs_lo,
  ['lt']: dayjs_lt,
  ['lv']: dayjs_lv,
  ['me']: dayjs_me,
  ['mi']: dayjs_mi,
  ['mk']: dayjs_mk,
  ['ml']: dayjs_ml,
  ['mn']: dayjs_mn,
  ['mr']: dayjs_mr,
  ['ms-my']: dayjs_ms_my,
  ['ms']: dayjs_ms,
  ['mt']: dayjs_mt,
  ['my']: dayjs_my,
  ['nb']: dayjs_nb,
  ['ne']: dayjs_ne,
  ['nl-be']: dayjs_nl_be,
  ['nl']: dayjs_nl,
  ['nn']: dayjs_nn,
  ['oc-lnc']: dayjs_oc_lnc,
  ['pa-in']: dayjs_pa_in,
  ['pl']: dayjs_pl,
  ['pt-br']: dayjs_pt_br,
  ['pt']: dayjs_pt,
  ['rn']: dayjs_rn,
  ['ro']: dayjs_ro,
  ['ru']: dayjs_ru,
  ['rw']: dayjs_rw,
  ['sd']: dayjs_sd,
  ['se']: dayjs_se,
  ['si']: dayjs_si,
  ['sk']: dayjs_sk,
  ['sl']: dayjs_sl,
  ['sq']: dayjs_sq,
  ['sr-cyrl']: dayjs_sr_cyrl,
  ['sr']: dayjs_sr,
  ['ss']: dayjs_ss,
  ['sv-fi']: dayjs_sv_fi,
  ['sv']: dayjs_sv,
  ['sw']: dayjs_sw,
  ['ta']: dayjs_ta,
  ['te']: dayjs_te,
  ['tet']: dayjs_tet,
  ['tg']: dayjs_tg,
  ['th']: dayjs_th,
  ['tk']: dayjs_tk,
  ['tl-ph']: dayjs_tl_ph,
  ['tlh']: dayjs_tlh,
  ['tr']: dayjs_tr,
  ['tzl']: dayjs_tzl,
  ['tzm-latn']: dayjs_tzm_latn,
  ['tzm']: dayjs_tzm,
  ['ug-cn']: dayjs_ug_cn,
  ['uk']: dayjs_uk,
  ['ur']: dayjs_ur,
  ['uz-latn']: dayjs_uz_latn,
  ['uz']: dayjs_uz,
  ['vi']: dayjs_vi,
  ['x-pseudo']: dayjs_x_pseudo,
  ['yo']: dayjs_yo,
  ['zh-cn']: dayjs_zh_cn,
  ['zh-hk']: dayjs_zh_hk,
  ['zh-tw']: dayjs_zh_tw,
  ['zh']: dayjs_zh,
};

export const getAntdLocale = (locale = navigator.language) => {
  const kebabLocale = toKebabCase(locale);
  const antdLocale =
    AntdLocaleMap[kebabLocale] ||
    AntdLocaleMap[kebabLocale.split('-')[0]] ||
    AntdLocaleMap['en-us'];

  return antdLocale;
};

export const loadDayjsLocale = (locale = navigator.language) => {
  const kebabLocale = toKebabCase(locale);
  const dayjsLocale =
    DayjsLocaleMap[kebabLocale] ||
    DayjsLocaleMap[kebabLocale.split('-')[0]] ||
    DayjsLocaleMap['en'];

  dayjs.locale(kebabLocale, dayjsLocale);
};
