import React, { FC } from 'react';

import { Image } from '../Image';
import { getIconUrl } from '../../utils';
import { PrintButtonProps } from './PrintButton.types';

const defaultPrintIconSrc = getIconUrl('print');
export const PrintButton: FC<PrintButtonProps> = ({
  className,
  link,
  printIconSrc,
}) => {
  if (!printIconSrc) printIconSrc = defaultPrintIconSrc;

  return (
    <a href={link} target="_blank" rel="noreferrer" className={className}>
      <Image src={printIconSrc} />
    </a>
  );
};

export default PrintButton;
