import React from 'react';
import cn from 'classnames';
import AntdTooltip from 'antd/lib/tooltip';
import AntdConfigProvider from 'antd/lib/config-provider';

import { TooltipProps } from './Tooltip.types';
import { getAntdLocale } from '../../utils';

import './Tooltip.scss';

export const Tooltip: React.FC<TooltipProps> = ({
  className,
  locale = navigator.language,
  ...rest
}) => {
  const antdLocale = getAntdLocale(locale);

  return (
    <AntdConfigProvider locale={antdLocale}>
      <AntdTooltip
        data-testid="tooltip"
        overlayClassName={cn('lex-tooltip', className)}
        destroyTooltipOnHide
        {...rest}
      />
    </AntdConfigProvider>
  );
};

export default Tooltip;
