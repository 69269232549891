import React from 'react';
import cn from 'classnames';

import { SpinnerProps } from './Spinner.types';

import './Spinner.scss';

export const Spinner: React.FC<SpinnerProps> = ({
  id,
  style,
  className,
  indicatorStyle,
  indicatorClassName,
  spinningStyle,
  spinningClassName,
  spinning = true,
  fullscreen,
  variant = 'primary',
  size = 'medium',
  placement = 'center',
  message,
  children,
}) => {
  const indicatorClasses = cn(
    'lex-spinner__indicator',
    indicatorClassName,
    spinning && 'lex-spinner__indicator--spinning',
    spinning && spinningClassName,
  );

  const indicatorStyles = spinning
    ? { ...indicatorStyle, ...spinningStyle }
    : indicatorStyle;

  const shouldCover = !!children;

  return (
    <div
      data-testid="spinner"
      id={id}
      style={style}
      className={cn(
        'lex-spinner',
        `lex-spinner--size-${size}`,
        `lex-spinner--placement-${placement}`,
        shouldCover && 'lex-spinner--cover',
        fullscreen && 'lex-spinner--fullscreen',
        variant && `lex-spinner--${variant}`,
        className,
      )}
    >
      <div className={indicatorClasses} style={indicatorStyles}>
        {spinning && (
          <>
            <div className={cn('lex-spinner__indicator-inner')}></div>
            <div className={cn('lex-spinner__indicator-message')}>
              {message}
            </div>
          </>
        )}
      </div>
      {children}
    </div>
  );
};

export default Spinner;
