import { isDate, isString } from 'lodash';
import { DateTime } from 'luxon';

import { isBlank } from './strings';

/**
 * Returns a date string formatted in the specified locale
 * @param {(Date|string|null|undefined)} - The date
 * @param {(locale|undefined)} - The locale
 * @param {(boolean)} - If the date should be converted to UTC first
 * @returns {(string)} - The date string
 */
export const formatDate = (
  date?: Date | string | null,
  locale = navigator.language,
  toUTC = false,
): string => {
  let dtObj = getDateTimeObject(date);
  let localeDate = '';

  if (dtObj) {
    if (toUTC) {
      dtObj = dtObj.toUTC();
    }
    localeDate = dtObj.setLocale(locale).toLocaleString(DateTime.DATE_MED);
  }

  return localeDate;
};

/**
 * Returns a date time string formatted in the specified locale
 * @param {(Date|string|null|undefined)} - The date time
 * @param {(locale|undefined)} - The locale
 * @param {(boolean)} - If the date should be converted to UTC first
 * @returns {(string)} - The date time string
 */
export const formatDateTime = (
  date?: Date | string | null,
  locale = navigator.language,
  toUTC = false,
): string => {
  let dtObj = getDateTimeObject(date);
  let localeDateTime = '';

  if (dtObj) {
    if (toUTC) {
      dtObj = dtObj.toUTC();
    }
    localeDateTime = dtObj
      .setLocale(locale)
      .toLocaleString(DateTime.DATETIME_MED);
  }

  return localeDateTime;
};

/**
 * Returns a time string formatted in the specified locale
 * @param {(Date|string|null|undefined)} - The date
 * @param {(locale|undefined)} - The locale
 * @param {(boolean)} - If the date should be converted to UTC first
 * @returns {(string)} - The time string
 */
export const formatTime = (
  date?: Date | string | null,
  locale = navigator.language,
  toUTC = false,
): string => {
  let dtObj = getDateTimeObject(date);
  let localeTime = '';

  if (dtObj) {
    if (toUTC) {
      dtObj = dtObj.toUTC();
    }
    localeTime = dtObj.setLocale(locale).toLocaleString(DateTime.TIME_SIMPLE);
  }

  return localeTime;
};

/**
 * Returns a string of this time relative to now formatted in the specified locale
 * @param {(Date|string|null|undefined)} - The date
 * @param {(locale|undefined)} - The locale
 * @param {(boolean)} - If the date should be converted to UTC first
 * @returns {(string)} - The relative string
 */
export const formatRelative = (
  date?: Date | string | null,
  locale = navigator.language,
  toUTC = false,
): string => {
  let dtObj = getDateTimeObject(date);
  let localeRelative = '';

  if (dtObj) {
    if (toUTC) {
      dtObj = dtObj.toUTC();
    }
    localeRelative = dtObj.setLocale(locale).toRelative() || '';
  }

  return localeRelative;
};

/**
 * Returns a string of this date relative to today formatted in the specified locale
 * @param {(Date|string|null|undefined)} - The date
 * @param {(locale|undefined)} - The locale
 * @param {(boolean)} - If the date should be converted to UTC first
 * @returns {(string)} - The relative calendar string
 */
export const formatRelativeCalendar = (
  date?: Date | string | null,
  locale = navigator.language,
  toUTC = false,
): string => {
  let dtObj = getDateTimeObject(date);
  let localeRelativeCalendar = '';

  if (dtObj) {
    if (toUTC) {
      dtObj = dtObj.toUTC();
    }
    localeRelativeCalendar = dtObj.setLocale(locale).toRelativeCalendar() || '';
  }

  return localeRelativeCalendar;
};

/**
 * Returns a timestamp string formatted in the specified locale
 * @param {(Date|string|null|undefined)} - The date
 * @param {(locale|undefined)} - The locale
 * @param {(boolean)} - If the date should be converted to UTC first
 * @returns {(string)} - The timestamp string
 */
export const formatTimestamp = (
  date?: Date | string | null,
  locale = navigator.language,
  toUTC = false,
): string => {
  let dtObj = getDateTimeObject(date);
  let localeTimestamp = '';

  if (dtObj) {
    if (toUTC) {
      dtObj = dtObj.toUTC();
    }
    localeTimestamp = dtObj
      .setLocale(locale)
      .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
  }

  return localeTimestamp;
};

/**
 * Returns a DateTime object
 * @param {(Date|string|null|undefined)} - The date
 * @returns {(DateTime|null|undefined)} - The DateTime object
 */
export const getDateTimeObject = (
  date?: Date | string | null,
): DateTime | null | undefined => {
  let dateTime;

  if (isBlank(date)) {
    dateTime = null;
  } else if (isDate(date)) {
    dateTime = DateTime.fromJSDate(date);
  } else if (isString(date)) {
    dateTime = DateTime.fromISO(date);
  } else {
    console.error('Could not convert to DateTime');
  }

  return dateTime;
};

/**
 * Returns true if the locale uses a 24-hour time format
 * @param {(locale|undefined)} - The locale
 * @returns {(boolean)} - If the locale uses a 24-hour time format
 */
export const is24HourTimeLocale = (locale = navigator.language): boolean => {
  const intl = new Intl.DateTimeFormat(locale, {
    hour: 'numeric',
  });

  return (
    (intl
      .formatToParts(new Date(2000, 0, 1, 13))
      .find((part) => part.type === 'hour')?.value.length ?? 2) === 2
  );
};
