import React from 'react';

import { Variant } from '../../types';

export interface StepperProps {
  id?: string;
  style?: React.CSSProperties;
  stepStyle?: React.CSSProperties;
  stepCircleStyle?: React.CSSProperties;
  stepTitleStyle?: React.CSSProperties;
  stepSubtitleStyle?: React.CSSProperties;
  stepNextStyle?: React.CSSProperties;
  className?: string;
  stepClassName?: string;
  stepCircleClassName?: string;
  stepTitleClassName?: string;
  stepSubtitleClassName?: string;
  stepNextClassName?: string;
  variant?: Variant;
  orientation?: 'vertical' | 'horizontal';
  steps?: Step[];
  activeStepId?: string | null;
  useConnector?: boolean;
  connectorSize?: number;
  iconSize?: 'small' | 'medium' | 'large';
  errorIconSrc?: string;
  approvedIconSrc?: string;
  rejectedIconSrc?: string;
  returnedIconSrc?: string;
  recordedIconSrc?: string;
  skippedIconSrc?: string;
  nextIconSrc?: string;
  notStartedIconTitle?: string;
  inProgressIconTitle?: string;
  errorIconTitle?: string;
  approvedIconTitle?: string;
  rejectedIconTitle?: string;
  returnedIconTitle?: string;
  recordedIconTitle?: string;
  skippedIconTitle?: string;
}

export interface Step {
  id: string;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  status?: StepStatus;
}

export enum StepStatus {
  NOT_STARTED = 'not-started',
  IN_PROGRESS = 'in-progress',
  ERROR = 'error',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  RETURNED = 'returned',
  RECORDED = 'recorded',
  SKIPPED = 'skipped',
}
