import React, { useRef, useState, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { Dropdown as AntdDropdown, Menu as AntdMenu } from 'antd';
import AntdConfigProvider from 'antd/lib/config-provider';

import Image from '../Image';
import Button from '../Button';
import { ButtonListProps } from './ButtonList.types';
import { getAntdLocale, getIconUrl } from '../../utils';

import './ButtonList.scss';

const defArrowIconSrc = getIconUrl('arrow_drop_down');

export const ButtonList: React.FC<ButtonListProps> = ({
  style,
  menuStyle,
  className,
  menuClassName,
  dropdownButton,
  menuButtons,
  arrowIconSrc,
  disabled,
  locale = navigator.language,
}) => {
  if (!arrowIconSrc) arrowIconSrc = defArrowIconSrc;

  const ref = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const antdLocale = getAntdLocale(locale);

  const clickListener = useCallback(
    (e: Event) => {
      if (!ref.current?.contains(e.target as Node)) {
        setShowMenu(false);
      }
    },
    [ref.current],
  );

  const scrollListener = useCallback(
    (e: Event) => {
      if (!(e.target as Element).closest('.ant-dropdown')) {
        setShowMenu(false);
      }
    },
    [ref.current],
  );

  useEffect(() => {
    document.addEventListener('click', clickListener);
    return () => {
      document.removeEventListener('click', clickListener);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollListener, true);
    return () => {
      window.removeEventListener('scroll', scrollListener, true);
    };
  }, []);

  const {
    children,
    variant = 'primary',
    ...restDropdownButton
  } = dropdownButton;

  const overlay = (
    <AntdMenu
      className={cn('lex-button-list__menu', menuClassName)}
      style={menuStyle}
      data-testid="button-list-menu"
      items={menuButtons.map((menuButton, idx) => ({
        key: idx,
        className: cn('lex-button-list__menu-item'),
        label: (
          <Button
            data-testid="button-list-menu-item-button"
            className={cn('lex-button-list__menu-item-button')}
            rounded={false}
            onClick={(e) => {
              menuButton.onClick?.(e);
              setShowMenu(false);
            }}
            {...menuButton}
          />
        ),
      }))}
    />
  );

  return (
    <div
      data-testid="button-list"
      className={cn(
        'lex-button-list',
        disabled && `lex-button-list--disabled`,
        className,
      )}
      style={style}
      ref={ref}
    >
      <AntdConfigProvider locale={antdLocale}>
        <AntdDropdown
          data-testid="button-list-dropdown"
          className={cn(
            'lex-button-list__dropdown',
            variant && `lex-button-list__dropdown-button--${variant}`,
          )}
          overlay={overlay}
          trigger={['click']}
          open={showMenu}
          placement="bottomRight"
          disabled={disabled}
        >
          <Button
            data-testid="button-list-dropdown-button"
            className={cn(
              'lex-button-list__dropdown-button',
              variant && `lex-button-list__dropdown-button--${variant}`,
              showMenu && 'active',
            )}
            type="button"
            variant={variant}
            onClick={() => setShowMenu(!showMenu)}
            {...restDropdownButton}
          >
            {children && (
              <span
                data-testid="button-list-dropdown-button-span"
                className={cn('lex-button-list__dropdown-button-span')}
              >
                {children}
              </span>
            )}
            <Image
              data-testid="button-list-dropdown-button-image"
              className={cn('lex-button-list__dropdown-button-image')}
              src={arrowIconSrc}
            />
          </Button>
        </AntdDropdown>
      </AntdConfigProvider>
    </div>
  );
};

export default ButtonList;
