import React from 'react';
import cn from 'classnames';

import { BreadcrumbsProps } from './Breadcrumbs.types';

import './Breadcrumbs.scss';

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  style,
  className,
  pageTrail = [],
  separator = '>',
}) => {
  if (!pageTrail.length) {
    return null;
  }

  const sepSpan = (
    <span className={cn('lex-breadcrumbs__separator')}>{separator}</span>
  );

  return (
    <div
      data-testid="breadcrumbs"
      className={cn('lex-breadcrumbs', className)}
      style={style}
    >
      {pageTrail.map(({ href, onClick, children, title, ...rest }, idx) => {
        const showSeparator = idx < pageTrail.length - 1;
        const contents = children || title;

        if (href || onClick) {
          return (
            <div key={idx}>
              <a
                data-testid="breadcrumbs-link"
                className={cn('lex-breadcrumbs__link')}
                href={href}
                onClick={onClick}
                title={title}
                {...rest}
              >
                {contents}
              </a>
              {showSeparator && sepSpan}
            </div>
          );
        }

        return (
          <div key={idx}>
            <span
              data-testid="breadcrumbs-text"
              className={cn('lex-breadcrumbs__title')}
            >
              {contents}
            </span>
            {showSeparator && sepSpan}
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
