import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import AntPagination from 'antd/lib/pagination';
import AntdConfigProvider from 'antd/lib/config-provider';

import { PaginationProps } from './Pagination.types';
import { Image } from '../Image';
import { getAntdLocale, getIconUrl } from '../../utils';

import './Pagination.scss';

const classPrefix = 'lex-pagination';
const defPrevIconSrc = getIconUrl('chevron_left');
const defNextIconSrc = getIconUrl('chevron_right');
const defSize = 10;
const defPage = 1;

export const Pagination: React.FC<PaginationProps> = ({
  className,
  totalClassName,
  previousButtonClassName,
  nextButtonClassName,
  style,
  previousIconSrc,
  nextIconSrc,
  pageSize,
  defaultPageSize = defSize,
  current,
  defaultCurrent = defPage,
  total,
  onChange,
  disabled,
  locale = navigator.language,
  ...rest
}) => {
  if (!previousIconSrc) previousIconSrc = defPrevIconSrc;
  if (!nextIconSrc) nextIconSrc = defNextIconSrc;

  const [size, setSize] = useState(pageSize || defaultPageSize);
  const [page, setPage] = useState(current || defaultCurrent);

  const antdLocale = getAntdLocale(locale);

  useEffect(() => {
    // If new pageSize prop value is passed in
    setSize(pageSize || defaultPageSize);
  }, [pageSize]);

  useEffect(() => {
    // If new current prop value is passed in
    setPage(current || defaultCurrent);
  }, [current]);

  useEffect(() => {
    // Reset page if total is less than current page based on size
    if (page && size && total && (page - 1) * size > total) {
      setPage(1);
    }
  });

  if (!total) {
    return null;
  }

  const pageBegin = (page - 1) * size + 1;
  const pageEnd = Math.min(pageBegin + size - 1, total);

  return (
    <div
      data-testid="pagination"
      className={cn(
        classPrefix,
        total <= size && `${classPrefix}--no-buttons`,
        className,
      )}
      style={style}
    >
      <AntdConfigProvider locale={antdLocale}>
        <AntPagination
          pageSize={size}
          current={page}
          total={total}
          showLessItems
          showQuickJumper={false}
          showSizeChanger={false}
          showTitle={false}
          showTotal={() => null}
          onChange={(page: number, pageSize = defaultPageSize) => {
            setSize(pageSize);
            setPage(page);
            onChange?.(page, pageSize);
          }}
          disabled={disabled}
          itemRender={(_page, type, originalElement) => {
            if (type === 'prev') {
              return (
                <div
                  role="button"
                  data-testid="pagination-previous"
                  className={cn(
                    `${classPrefix}__button`,
                    `${classPrefix}__previous`,
                    previousButtonClassName,
                    disabled && `${classPrefix}__button--disabled`,
                  )}
                  tabIndex={0}
                >
                  <Image src={previousIconSrc} />
                </div>
              );
            }
            if (type === 'next') {
              return (
                <div
                  role="button"
                  data-testid="pagination-next"
                  className={cn(
                    `${classPrefix}__button`,
                    `${classPrefix}__next`,
                    nextButtonClassName,
                    disabled && `${classPrefix}__button--disabled`,
                  )}
                  tabIndex={0}
                >
                  <Image src={nextIconSrc} />
                </div>
              );
            }
            return originalElement;
          }}
          {...rest}
        />
      </AntdConfigProvider>
      <span
        data-testid="pagination-total"
        className={cn(`${classPrefix}__total`, totalClassName)}
      >
        {`${pageBegin}-${pageEnd} / ${total}`}
      </span>
    </div>
  );
};

export default Pagination;
