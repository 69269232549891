import React from 'react';
import cn from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import AntdConfigProvider from 'antd/lib/config-provider';

import { DatePickerProps } from './DatePicker.types';
import Image from '../Image';
import { withFormField } from '../../hocs/withFormField';
import {
  getAntdLocale,
  getIconUrl,
  is24HourTimeLocale,
  loadDayjsLocale,
} from '../../utils';

import './DatePicker.scss';

dayjs.extend(utc);

const AntDatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

const DATE_ISO = 'YYYY-MM-DD';
const DATETIME_SEP = 'T';
const TIME_ISO = 'HH:mm';
const DATETIME_ISO_NO_SEC = `${DATE_ISO}${DATETIME_SEP}${TIME_ISO}[Z]`;
const TIME_DISP_12HRS = 'h:mm A';
const TIME_DISP_24HRS = 'H:mm';
const DATE_DISP = 'MMM D, YYYY';
const DATETIME_DISP_12HRS = `${DATE_DISP}, ${TIME_DISP_12HRS}`;
const DATETIME_DISP_24HRS = `${DATE_DISP}, ${TIME_DISP_24HRS}`;

export const DatePicker: React.FC<DatePickerProps> = ({
  id,
  style,
  className,
  pickerClassName,
  variant,
  disabled,
  placeholder,
  format,
  defaultValue,
  value,
  hiddenLabel,
  error,
  onChange,
  showTime,
  timeProps,
  locale = navigator.language,
  ...rest
}) => {
  const is24Hrs = is24HourTimeLocale(locale);
  const antdLocale = getAntdLocale(locale);
  loadDayjsLocale(locale);

  let timeInfo: typeof timeProps | undefined;

  if (showTime) {
    timeInfo =
      timeProps ||
      (is24Hrs ? { format: TIME_DISP_24HRS } : { format: TIME_DISP_12HRS });
  }

  const dateFormat: DatePickerProps['format'] =
    format ||
    ((value) =>
      showTime
        ? value
            .locale(locale)
            .format(is24Hrs ? DATETIME_DISP_24HRS : DATETIME_DISP_12HRS)
        : value.locale(locale).format(DATE_DISP));

  return (
    <div
      data-testid="date-picker"
      className={cn(
        'lex-date-picker',
        variant && `lex-date-picker--${variant}`,
        error && 'lex-date-picker--error',
        disabled && 'lex-date-picker--disabled',
        className,
      )}
      style={style}
    >
      {hiddenLabel && (
        <label className="screen-reader" htmlFor={id}>
          {hiddenLabel}
        </label>
      )}
      <AntdConfigProvider locale={antdLocale}>
        <AntDatePicker
          id={id}
          data-testid="date-picker-input"
          className={cn('lex-date-picker__picker', pickerClassName)}
          getPopupContainer={(triggerNode) =>
            triggerNode.parentElement ?? triggerNode
          }
          disabled={disabled}
          placeholder={placeholder ?? ''}
          format={dateFormat}
          showTime={timeInfo}
          defaultValue={defaultValue ? dayjs(defaultValue) : undefined}
          value={value ? dayjs(value) : undefined}
          suffixIcon={
            <Image
              className={cn('lex-date-picker__picker-icon')}
              src={getIconUrl('calendar_today')}
            />
          }
          clearIcon={
            <Image
              className={cn('lex-date-picker__clear-icon')}
              src={getIconUrl('clear')}
            />
          }
          onChange={(value) => {
            if (!value) {
              onChange?.('', value);
              return;
            }

            const isoValue = showTime
              ? dayjs(value).utc().format(DATETIME_ISO_NO_SEC)
              : dayjs(value).format(DATE_ISO);

            onChange?.(isoValue, value);
          }}
          {...rest}
        />
      </AntdConfigProvider>
    </div>
  );
};

export default DatePicker;

export const DatePickerFormField = withFormField(DatePicker);
