import React, { useState } from 'react';
import cn from 'classnames';

import { WithFormFieldProps } from './withFormField.types';
import { Image } from '../../components/Image';
import { Modal } from '../../components/Modal';
import { getIconUrl } from '../../utils';

import './withFormField.scss';

const classPrefix = 'lex-with-form-field';
const defInfoIconSrc = getIconUrl('info');
const defErrorIconSrc = getIconUrl('warning');

export const withFormField = <T extends unknown>(
  Component: React.ComponentType<T>,
): React.FC<T & WithFormFieldProps> => {
  // eslint-disable-next-line react/display-name
  return ({
    formFieldStyle,
    formFieldClassName,
    label,
    labelStyle,
    labelClassName,
    infoIconSrc,
    infoIconTitle = 'More Info',
    infoModalStyle,
    infoModalClassName,
    infoModalContainerClassName,
    infoModalWrapClassName,
    infoText,
    infoTextStyle,
    infoTextClassName,
    errorIconSrc,
    errorText,
    errorStyle,
    errorClassName,
    ...rest
  }: WithFormFieldProps) => {
    if (!infoIconSrc) infoIconSrc = defInfoIconSrc;
    if (!errorIconSrc) errorIconSrc = defErrorIconSrc;

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showFormLabel = !!label || !!infoText;

    const formLabel = showFormLabel && (
      <div
        className={cn(`${classPrefix}__label-container`, labelClassName)}
        style={labelStyle}
      >
        {label && (
          <span
            data-testid="with-form-field-label"
            className={cn(`${classPrefix}__label`, 'rich-text')}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        )}
        {infoText && (
          <>
            <div
              data-testid="with-form-field-icon"
              role="button"
              tabIndex={0}
              className={cn(`${classPrefix}__icon-container`)}
              onClick={() => setIsModalVisible(true)}
            >
              <Image
                className={cn(`${classPrefix}__icon`)}
                src={infoIconSrc}
                title={infoIconTitle}
              />
            </div>
            <Modal
              closable
              className={cn(`${classPrefix}__info-modal`, infoModalClassName)}
              containerClassName={cn(
                `${classPrefix}__info-modal-container`,
                infoModalContainerClassName,
              )}
              wrapClassName={cn(
                `${classPrefix}__info-modal-wrap`,
                infoModalWrapClassName,
              )}
              style={infoModalStyle}
              open={isModalVisible}
              onCancel={() => setIsModalVisible(false)}
            >
              <p
                data-testid="with-form-field-info-text"
                className={cn(
                  `${classPrefix}__info-text`,
                  'rich-text',
                  infoTextClassName,
                )}
                style={infoTextStyle}
                dangerouslySetInnerHTML={{
                  __html: infoText,
                }}
              />
            </Modal>
          </>
        )}
      </div>
    );

    const showError = !!errorText;

    const errorLabel = showError && (
      <div
        className={cn(`${classPrefix}__error-container`, errorClassName)}
        style={errorStyle}
      >
        <Image
          className={cn(`${classPrefix}__error-icon`)}
          src={errorIconSrc}
        />
        <span
          data-testid="with-form-field-error"
          className={cn(`${classPrefix}__error-text`, 'rich-text')}
          dangerouslySetInnerHTML={{
            __html: errorText,
          }}
        />
      </div>
    );

    const restProps = Object.assign({}, rest as T);

    return (
      <div
        data-testid="with-form-field"
        className={cn(classPrefix, formFieldClassName)}
        style={formFieldStyle}
      >
        {formLabel}
        <div className={cn(`${classPrefix}__component-container`)}>
          <Component {...restProps} hiddenLabel={label} error={errorText} />
          {errorLabel}
        </div>
      </div>
    );
  };
};

export default withFormField;
