import React from 'react';
import cn from 'classnames';
import AntCheckbox from 'antd/lib/checkbox';
import AntdConfigProvider from 'antd/lib/config-provider';

import { CheckboxProps } from './Checkbox.types';

import './Checkbox.scss';
import { getAntdLocale } from '../../utils';

export const Checkbox: React.FC<CheckboxProps> = ({
  id,
  className,
  variant = 'primary',
  disabled,
  error,
  locale = navigator.language,
  ...rest
}) => {
  const antdLocale = getAntdLocale(locale);

  return (
    <AntdConfigProvider locale={antdLocale}>
      <AntCheckbox
        id={id}
        data-testid="checkbox"
        className={cn(
          'lex-checkbox',
          variant && `lex-checkbox--${variant}`,
          error && 'lex-checkbox--error',
          disabled && 'lex-checkbox--disabled',
          className,
        )}
        disabled={disabled}
        {...rest}
      />
    </AntdConfigProvider>
  );
};

export default Checkbox;
