import React, { useRef, useState, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { Dropdown as AntdDropdown, Menu as AntdMenu } from 'antd';
import AntdConfigProvider from 'antd/lib/config-provider';

import Image from '../Image';
import Button from '../Button';
import Tooltip from '../Tooltip';
import { ButtonMenuProps } from './ButtonMenu.types';
import { getAntdLocale, getIconUrl } from '../../utils';

import './ButtonMenu.scss';

const defArrowIconSrc = getIconUrl('arrow_drop_down');

export const ButtonMenu: React.FC<ButtonMenuProps> = ({
  style,
  menuStyle,
  className,
  menuClassName,
  dropdownButton,
  menuItems,
  arrowIconSrc,
  disabled,
  locale = navigator.language,
}) => {
  if (!arrowIconSrc) arrowIconSrc = defArrowIconSrc;

  const ref = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const antdLocale = getAntdLocale(locale);

  const clickListener = useCallback(
    (e: Event) => {
      if (!ref.current?.contains(e.target as Node)) {
        setShowMenu(false);
      }
    },
    [ref.current],
  );

  const scrollListener = useCallback(
    (e: Event) => {
      if (!(e.target as Element).closest('.ant-dropdown')) {
        setShowMenu(false);
      }
    },
    [ref.current],
  );

  useEffect(() => {
    document.addEventListener('click', clickListener);
    return () => {
      document.removeEventListener('click', clickListener);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollListener, true);
    return () => {
      window.removeEventListener('scroll', scrollListener, true);
    };
  }, []);

  const {
    children,
    variant = 'primary',
    ...restDropdownButton
  } = dropdownButton;

  const overlay = (
    <AntdMenu
      className={cn(
        'lex-button-menu__menu',
        variant && `lex-button-menu__menu--${variant}`,
        menuClassName,
      )}
      style={menuStyle}
      data-testid="button-menu-menu"
      items={menuItems.map(({ title, children, onClick, ...rest }, idx) => ({
        key: idx,
        className: cn('lex-button-menu__menu-item'),
        label: (
          <Tooltip title={title}>
            <span>{children}</span>
          </Tooltip>
        ),
        onClick: (e) => {
          onClick?.(e);
          setShowMenu(false);
        },
        ...rest,
      }))}
    />
  );

  return (
    <div
      data-testid="button-menu"
      className={cn(
        'lex-button-menu',
        disabled && `lex-button-menu--disabled`,
        className,
      )}
      style={style}
      ref={ref}
    >
      <AntdConfigProvider locale={antdLocale}>
        <AntdDropdown
          data-testid="button-menu-dropdown"
          className={cn('lex-button-menu__dropdown')}
          overlay={overlay}
          trigger={['click']}
          open={showMenu}
          placement="bottomRight"
          disabled={disabled}
        >
          <Button
            data-testid="button-menu-dropdown-button"
            className={cn(
              'lex-button-menu__dropdown-button',
              variant && `lex-button-menu__dropdown-button--${variant}`,
              showMenu && 'active',
            )}
            type="button"
            variant={variant}
            onClick={() => setShowMenu(!showMenu)}
            {...restDropdownButton}
          >
            {children && (
              <span
                data-testid="button-menu-dropdown-button-span"
                className={cn('lex-button-menu__dropdown-button-span')}
              >
                {children}
              </span>
            )}
            <Image
              data-testid="button-menu-dropdown-button-image"
              className={cn('lex-button-menu__dropdown-button-image')}
              src={arrowIconSrc}
            />
          </Button>
        </AntdDropdown>
      </AntdConfigProvider>
    </div>
  );
};

export default ButtonMenu;
