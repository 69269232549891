import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { ScrollHintProps } from './ScrollHint.types';
import { Image } from '../Image';
import { getIconUrl } from '../../utils';

import './ScrollHint.scss';

const defUpIconSrc = getIconUrl('keyboard_double_arrow_up');
const defDownIconSrc = getIconUrl('keyboard_double_arrow_down');
const defLeftIconSrc = getIconUrl('keyboard_double_arrow_left');
const defRightIconSrc = getIconUrl('keyboard_double_arrow_right');

export const ScrollHint: React.FC<ScrollHintProps> = ({
  children,
  iconStyle,
  iconClassName,
  type = 'both',
  showUpIcon = true,
  showLeftIcon = true,
  upIconSrc,
  downIconSrc,
  leftIconSrc,
  rightIconSrc,
  animateSeconds = 4,
}) => {
  if (!upIconSrc) upIconSrc = defUpIconSrc;
  if (!downIconSrc) downIconSrc = defDownIconSrc;
  if (!leftIconSrc) leftIconSrc = defLeftIconSrc;
  if (!rightIconSrc) rightIconSrc = defRightIconSrc;

  const animation = `opacity-pulsate ${animateSeconds}s ease-in-out infinite`;

  const scrollHint = useRef<HTMLDivElement>(null);
  const upArrow = useRef<HTMLDivElement>(null);
  const downArrow = useRef<HTMLDivElement>(null);
  const leftArrow = useRef<HTMLDivElement>(null);
  const rightArrow = useRef<HTMLDivElement>(null);
  const [parent, setParent] = useState<HTMLDivElement>();
  const [mutationObserver, setMutationObserver] = useState<MutationObserver>();
  const [resizeObserver, setResizeObserver] = useState<ResizeObserver>();

  const checkScroll = useCallback(() => {
    if (!parent) {
      return;
    }

    // Parent must be relative in order to account for absolute arrows
    parent.style.position = 'relative';

    const {
      clientHeight,
      clientWidth,
      offsetWidth,
      scrollHeight,
      scrollLeft,
      scrollTop,
      scrollWidth,
    } = parent;

    const hasXScroll = type !== 'y' && scrollWidth > clientWidth;
    const hasYScroll = type !== 'x' && scrollHeight > clientHeight;
    const xyOffset = (offsetWidth - clientWidth) * 1.5;
    const padding = 7;

    const showUp = hasYScroll && scrollTop > 0;
    const showDown =
      hasYScroll &&
      Math.ceil(scrollTop) < scrollHeight - clientHeight - padding;
    const showLeft = hasXScroll && scrollLeft > 0;
    const showRight =
      hasXScroll && Math.ceil(scrollLeft) < scrollWidth - clientWidth;

    if (upArrow.current) {
      upArrow.current.style.animation = showUp ? animation : '';
      upArrow.current.style.display = showUp ? 'unset' : 'none';
      upArrow.current.style.top = `${scrollTop}px`;
      upArrow.current.style.right = `${scrollLeft * -1}px`;
    }

    if (downArrow.current) {
      downArrow.current.style.animation = showDown ? animation : '';
      downArrow.current.style.display = showDown ? 'unset' : 'none';
      downArrow.current.style.right = `${scrollLeft * -1}px`;
      downArrow.current.style.bottom = `${
        scrollTop * -1 + (hasXScroll ? xyOffset : 0) + padding
      }px`;
    }

    if (leftArrow.current) {
      leftArrow.current.style.animation = showLeft ? animation : '';
      leftArrow.current.style.display = showLeft ? 'unset' : 'none';
      leftArrow.current.style.bottom = `${scrollTop * -1 + padding}px`;
      leftArrow.current.style.left = `${scrollLeft}px`;
    }

    if (rightArrow.current) {
      rightArrow.current.style.animation = showRight ? animation : '';
      rightArrow.current.style.display = showRight ? 'unset' : 'none';
      rightArrow.current.style.bottom = `${scrollTop * -1 + padding}px`;
      rightArrow.current.style.right = `${
        scrollLeft * -1 + (hasYScroll ? xyOffset : 0)
      }px`;
    }
  }, [parent, upArrow, downArrow, leftArrow, rightArrow]);

  useEffect(() => {
    const parent = scrollHint.current?.parentElement as
      | HTMLDivElement
      | undefined;

    if (!parent) {
      return;
    }

    setParent(parent);

    // Wait for the parent container
    mutationObserver?.observe(parent, {
      childList: true,
      subtree: true,
    });
    resizeObserver?.observe(parent);
  }, [mutationObserver, resizeObserver, scrollHint.current?.parentElement]);

  useEffect(() => {
    if (!parent) {
      return;
    }

    const mo = new MutationObserver(() => {
      if (document.contains(parent)) {
        setTimeout(checkScroll, 0);
      }
    });
    setMutationObserver(mo);
    return () => mo.disconnect();
  }, [parent, checkScroll]);

  useEffect(() => {
    const ro = new ResizeObserver(() => {
      setTimeout(checkScroll, 0);
    });
    setResizeObserver(ro);
    return () => ro.disconnect();
  }, [checkScroll]);

  useEffect(() => {
    parent?.addEventListener('scroll', checkScroll);
    return () => parent?.removeEventListener('scroll', checkScroll);
  }, [parent, checkScroll]);

  return (
    <>
      {children}
      <div
        ref={scrollHint}
        data-testid="scroll-hint"
        className={cn('lex-scroll-hint')}
      />
      {type !== 'x' && showUpIcon && (
        <div
          ref={upArrow}
          data-testid="scroll-hint-icon"
          className={cn(
            'lex-scroll-hint__icon',
            'lex-scroll-hint__icon--up',
            iconClassName,
          )}
          style={iconStyle}
        >
          <Image src={upIconSrc} />
        </div>
      )}
      {type !== 'x' && (
        <div
          ref={downArrow}
          data-testid="scroll-hint-icon"
          className={cn(
            'lex-scroll-hint__icon',
            'lex-scroll-hint__icon--down',
            iconClassName,
          )}
          style={iconStyle}
        >
          <Image src={downIconSrc} />
        </div>
      )}
      {type !== 'y' && showLeftIcon && (
        <div
          ref={leftArrow}
          data-testid="scroll-hint-icon"
          className={cn(
            'lex-scroll-hint__icon',
            'lex-scroll-hint__icon--left',
            iconClassName,
          )}
          style={iconStyle}
        >
          <Image src={leftIconSrc} />
        </div>
      )}
      {type !== 'y' && (
        <div
          ref={rightArrow}
          data-testid="scroll-hint-icon"
          className={cn(
            'lex-scroll-hint__icon',
            'lex-scroll-hint__icon--right',
            iconClassName,
          )}
          style={iconStyle}
        >
          <Image src={rightIconSrc} />
        </div>
      )}
    </>
  );
};

export default ScrollHint;
