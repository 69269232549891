import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import { useDeepCompareEffect, useFirstMountState } from 'react-use';
import AntCheckboxGroup from 'antd/lib/checkbox/Group';
import AntdConfigProvider from 'antd/lib/config-provider';

import {
  CheckboxOptionType,
  CheckboxValueType,
  CheckboxGroupProps,
} from './CheckboxGroup.types';
import { Checkbox } from '../Checkbox';
import { withFormField } from '../../hocs/withFormField';

import './CheckboxGroup.scss';
import { getAntdLocale } from '../../utils';

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  id,
  className,
  checkboxClassName,
  variant = 'primary',
  disabled,
  block = false,
  options = [],
  value = undefined,
  defaultValue = [],
  onChange,
  hiddenLabel,
  error,
  locale = navigator.language,
  ...rest
}) => {
  const isFirstMount = useFirstMountState();
  const [checkedValues, setCheckedValues] = useState<CheckboxValueType[]>();

  const antdLocale = getAntdLocale(locale);

  useDeepCompareEffect(() => {
    setCheckedValues(isFirstMount ? value ?? defaultValue : value);
  }, [value, defaultValue]);

  const checkboxes = useMemo(
    () =>
      options.map((option) => {
        const { value, label } = option as CheckboxOptionType;
        return (
          <div key={`${value}`}>
            <Checkbox
              id={`${id}-${value}`}
              data-testid="checkbox-group-checkbox"
              className={cn('lex-checkbox-group__checkbox', checkboxClassName)}
              value={value}
              variant={variant}
              disabled={disabled}
              error={error}
            >
              {label}
            </Checkbox>
          </div>
        );
      }),
    [options, id, checkboxClassName, variant, disabled, error],
  );

  const handleChange = (newCheckedValues: CheckboxValueType[]) => {
    setCheckedValues(newCheckedValues);
    onChange?.(newCheckedValues);
  };

  return (
    <div id={id}>
      {hiddenLabel && (
        <label className="screen-reader" htmlFor={id}>
          {hiddenLabel}
        </label>
      )}

      <AntdConfigProvider locale={antdLocale}>
        <AntCheckboxGroup
          data-testid="checkbox-group"
          className={cn(
            'lex-checkbox-group',
            block && 'lex-checkbox-group--block',
            error && 'lex-checkbox-group--error',
            disabled && 'lex-checkbox-group--disabled',
            className,
          )}
          value={checkedValues}
          disabled={disabled}
          onChange={handleChange}
          {...rest}
        >
          {checkboxes}
        </AntCheckboxGroup>
      </AntdConfigProvider>
    </div>
  );
};

export default CheckboxGroup;

export const CheckboxGroupFormField = withFormField(CheckboxGroup);
