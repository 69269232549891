import React from 'react';
import cn from 'classnames';
import AntdPopover from 'antd/lib/popover';
import AntdConfigProvider from 'antd/lib/config-provider';

import { PopoverProps } from './Popover.types';
import { getAntdLocale } from '../../utils';

import './Popover.scss';

export const Popover: React.FC<PopoverProps> = ({
  className,
  variant,
  locale = navigator.language,
  ...rest
}) => {
  const antdLocale = getAntdLocale(locale);

  return (
    <AntdConfigProvider locale={antdLocale}>
      <AntdPopover
        data-testid="popover"
        overlayClassName={cn(
          'lex-popover',
          className,
          variant && `lex-popover--${variant}`,
        )}
        destroyTooltipOnHide
        {...rest}
      />
    </AntdConfigProvider>
  );
};

export default Popover;
