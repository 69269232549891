import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { useDeepCompareEffect } from 'react-use';
import { Progress } from 'antd';
import AntdConfigProvider from 'antd/lib/config-provider';

import {
  FileUploadFileProps,
  FileUploadProps,
  DEFAULT_ACCEPT,
} from './FileUpload.types';
import { Button } from '../Button';
import { Image } from '../Image';
import { Modal } from '../Modal';
import { Popconfirm } from '../Popconfirm';
import { getAntdLocale, getIconUrl } from '../../utils';
import { withFormField } from '../../hocs/withFormField';

import './FileUpload.scss';

const classPrefix = 'lex-file-upload';
const defFileIconSrc = getIconUrl('cloud_download');
const defRemoveIconSrc = getIconUrl('delete');
const defAccept = DEFAULT_ACCEPT.join(',');
export const MAXIMUM_FILE_SIZE = 52428800;

const formatSize = (bytes?: number) => {
  if (!bytes && bytes !== 0) {
    return '';
  }

  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];

  const i = ~~Math.floor(Math.log(bytes) / Math.log(1024));

  if (i == 0) {
    return `${bytes} ${sizes[i]}`;
  }
  return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
};

const fileToFileUploadFileProps = (file: File): FileUploadFileProps => {
  const { name, size, type, lastModified } = file;
  return {
    name,
    size,
    type,
    lastModified: new Date(lastModified),
    inputFile: file,
  };
};
export const FileUpload: React.FC<FileUploadProps> = ({
  id,
  style,
  className,
  inputClassName,
  buttonClassName,
  variant = 'primary',
  multiple = true,
  canEdit,
  canView,
  files = [],
  noFilesLabel = 'No files selected',
  maxFileSizeBytes = MAXIMUM_FILE_SIZE,
  maxFileSizeLabel = `The following file(s) were not uploaded due to a prohibited format or due to their large size (over `,
  uploadButtonLabel = 'Add Files',
  fileIconSrc,
  fileIconTitle = 'Download',
  removeIconSrc,
  removeIconTitle = 'Remove',
  removeButtonConfirmText = 'Are you sure?',
  removeButtonConfirmOkText = 'Yes',
  removeButtonConfirmCancelText = 'No',
  accept,
  disabled,
  onChange,
  onFileRemove,
  onFileClick,
  onFileAdded,
  hiddenLabel,
  error,
  locale = navigator.language,
  ...rest
}) => {
  if (!fileIconSrc) fileIconSrc = defFileIconSrc;
  if (!removeIconSrc) removeIconSrc = defRemoveIconSrc;
  if (!accept) accept = defAccept;

  const inputRef = useRef<HTMLInputElement>(null);
  const [uploadedFiles, setUploadedFiles] = useState(files);
  const [invalidFiles, setInvalidFiles] = useState<FileUploadFileProps[]>([]);
  const isEditable = !disabled && canEdit;
  const showNoFiles = disabled && uploadedFiles.length === 0;
  const showUploadedFiles = uploadedFiles.length > 0;
  const showContainer = showNoFiles || showUploadedFiles;

  const antdLocale = getAntdLocale(locale);

  useDeepCompareEffect(() => {
    // If new files prop value is passed in
    setUploadedFiles(files || []);
  }, [files]);

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === ' ' || e.key === 'Enter') {
      (document.activeElement as HTMLDivElement).click();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { validity, files } = e.target;

    if (!validity || !files || !files.length) {
      console.error('No valid files', files);
      return;
    }

    // Filter for new files only
    const newFiles: FileUploadFileProps[] = Array.from(files)
      .filter((file) => {
        const { name, size } = file;
        if (uploadedFiles.find((uf) => uf.name === name)) {
          return false;
        }
        if (accept) {
          const fileExtension = `.${name.split('.').pop()}`;
          const isValidExtension =
            accept?.indexOf(fileExtension.toLowerCase()) >= 0;
          if (!isValidExtension) {
            invalidFiles.push(fileToFileUploadFileProps(file));
            return false;
          }
        }

        const exceedsMaxFileSize = size >= maxFileSizeBytes;
        if (exceedsMaxFileSize) {
          invalidFiles.push(fileToFileUploadFileProps(file));
        }
        return !exceedsMaxFileSize;
      })
      .map((file) => {
        return fileToFileUploadFileProps(file);
      });

    const allFiles = [...newFiles];

    if (multiple) {
      // When multiple, prepend the already uploaded files
      allFiles.unshift(...uploadedFiles);
    }

    // Update the state
    setUploadedFiles(allFiles);

    // Upload the file using the passed in onChange event
    onChange?.(e);
    onFileAdded?.(newFiles, allFiles);

    // Clear out the input
    e.target.value = '';
  };

  const handleFileRemove = (file: FileUploadFileProps) => {
    // Remove file from the list
    const newFiles = uploadedFiles.filter((uf) => uf.name !== file.name);

    // Update the state
    setUploadedFiles(newFiles);

    // Remove the file using the passed in onFileRemove event
    onFileRemove?.(file, newFiles);
  };

  const renderFileListItem = (file: FileUploadFileProps) => {
    const { name, uploadProgress, uploadError } = file;
    return (
      <li
        key={name}
        data-testid="file-upload-list-item"
        className={cn(`${classPrefix}__files-list-item`)}
      >
        {renderFileLink(file)}
        {uploadProgress && (
          <AntdConfigProvider locale={antdLocale}>
            <Progress
              className={cn(`${classPrefix}__progress`)}
              percent={uploadProgress}
              status={
                uploadError
                  ? 'exception'
                  : uploadProgress < 100
                  ? 'active'
                  : undefined
              }
            />
          </AntdConfigProvider>
        )}
        {isEditable && (
          <Popconfirm
            data-testid="file-upload-confirm"
            placement="right"
            icon={<Image src={getIconUrl('warning')} />}
            title={<span>{removeButtonConfirmText}</span>}
            onConfirm={() => {
              setTimeout(() => {
                handleFileRemove(file);
              }, 0);
            }}
            getPopupContainer={(triggerNode) =>
              triggerNode.parentElement ?? triggerNode
            }
            okText={removeButtonConfirmOkText}
            cancelText={removeButtonConfirmCancelText}
          >
            <div
              role="button"
              data-testid="file-upload-remove"
              className={cn(`${classPrefix}__files-list-item-remove-btn`)}
              onKeyUp={handleKeyUp}
              tabIndex={0}
            >
              <Image src={removeIconSrc} title={removeIconTitle} />
            </div>
          </Popconfirm>
        )}
      </li>
    );
  };

  const renderFileInfo = (file: FileUploadFileProps) => {
    const { name, size } = file;
    return (
      <>
        <span className={cn(`${classPrefix}__files-list-item-info-name`)}>
          {name}
        </span>
        {size !== undefined && (
          <span className={cn(`${classPrefix}__files-list-item-info-size`)}>
            {`(${formatSize(size)})`}
          </span>
        )}
      </>
    );
  };

  const renderFileLink = (file: FileUploadFileProps) => {
    const info = renderFileInfo(file);

    if (!canEdit && !canView) {
      return info;
    }

    return (
      <a
        data-testid="file-upload-link"
        className={cn(`${classPrefix}__files-list-item-info`)}
        onClick={() => onFileClick?.(file, uploadedFiles)}
        onKeyUp={handleKeyUp}
        tabIndex={0}
      >
        <Image
          className={cn(`${classPrefix}__files-list-item-info-icon`)}
          src={fileIconSrc}
          title={fileIconTitle}
        />
        {info}
      </a>
    );
  };

  const renderInvalidFileItem = (file: FileUploadFileProps) => (
    <span
      key={file.name}
      className={cn(`${classPrefix}__files-list-item-invalid`)}
    >
      {renderFileInfo(file)}
    </span>
  );

  return (
    <div
      data-testid="file-upload"
      className={cn(
        classPrefix,
        error && `${classPrefix}--error`,
        disabled && `${classPrefix}--disabled`,
        className,
      )}
      style={style}
    >
      {showContainer && (
        <div className={cn(`${classPrefix}__files-container`)}>
          {showNoFiles && (
            <span className={cn(`${classPrefix}__files-none`)}>
              {noFilesLabel}
            </span>
          )}
          {showUploadedFiles && (
            <ul className={cn(`${classPrefix}__files-list`)}>
              {uploadedFiles.map((uploadedFile) => {
                return renderFileListItem(uploadedFile);
              })}
            </ul>
          )}
        </div>
      )}
      {isEditable && (
        <>
          <Modal
            className={cn(`${classPrefix}__invalid-modal`)}
            closable
            open={invalidFiles.length > 0}
            onCancel={() => {
              setInvalidFiles([]);
            }}
          >
            <div data-testid="file-upload-modal">
              <span
                className={cn(`${classPrefix}__invalid-modal-message`)}
              >{`${maxFileSizeLabel} ${formatSize(maxFileSizeBytes)}): `}</span>
              {invalidFiles.map((file) => renderInvalidFileItem(file))}
            </div>
          </Modal>
          <Button
            data-testid="file-upload-button"
            className={cn(`${classPrefix}__btn`, buttonClassName)}
            type="button"
            onClick={() => inputRef.current?.click()}
            variant={error ? 'danger' : variant}
          >
            {uploadButtonLabel}
          </Button>
          {hiddenLabel && (
            <label className="screen-reader" htmlFor={id}>
              {hiddenLabel}
            </label>
          )}
          <input
            id={id}
            type="file"
            data-testid="file-upload-input"
            className={cn(`${classPrefix}__input`, inputClassName)}
            onChange={handleChange}
            multiple={multiple}
            hidden
            accept={accept}
            ref={inputRef}
            {...rest}
          />
        </>
      )}
    </div>
  );
};

export default FileUpload;

export const FileUploadFormField = withFormField(FileUpload);
