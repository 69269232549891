import React from 'react';

import { ButtonVariant } from '../../types';
import { WithFormFieldProps } from '../../hocs/withFormField/withFormField.types';

type OmittedInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'hidden'
>;

export interface FileUploadProps extends OmittedInputProps {
  inputClassName?: string;
  buttonClassName?: string;
  variant?: ButtonVariant;
  canEdit?: boolean;
  canView?: boolean;
  files?: FileUploadFileProps[];
  noFilesLabel?: string;
  maxFileSizeBytes?: number;
  maxFileSizeLabel?: string;
  uploadButtonLabel?: React.ReactNode;
  fileIconSrc?: string;
  fileIconTitle?: string;
  removeIconSrc?: string;
  removeIconTitle?: string;
  removeButtonConfirmText?: React.ReactNode;
  removeButtonConfirmOkText?: React.ReactNode;
  removeButtonConfirmCancelText?: React.ReactNode;
  onFileRemove?(
    file: FileUploadFileProps,
    fileList: FileUploadFileProps[],
  ): void;
  onFileClick?(
    file: FileUploadFileProps,
    fileList: FileUploadFileProps[],
  ): void;
  onFileAdded?(
    addedFiles: FileUploadFileProps[],
    fileList: FileUploadFileProps[],
  ): void;
  hiddenLabel?: string;
  error?: string;
  locale?: string;
}

export interface FileUploadFileProps {
  id?: string;
  name: string;
  size?: number;
  type?: string;
  inputFile?: File;
  lastModified?: Date;
  uploadProgress?: number;
  uploadError?: boolean;
}

export type FileUploadFormFieldProps = FileUploadProps & WithFormFieldProps;

export const DEFAULT_ACCEPT = [
  '.7z',
  '.bmp',
  '.css',
  '.csv',
  '.dat',
  '.del',
  '.doc',
  '.docb',
  '.docm',
  '.docx',
  '.dot',
  '.dotm',
  '.dotx',
  '.email',
  '.eml',
  '.emlx',
  '.gif',
  '.gz',
  '.htm',
  '.html',
  '.jpeg',
  '.jpg',
  '.json',
  '.mpp',
  '.mpt',
  '.mpx',
  '.mpw',
  '.msg',
  '.odf',
  '.odg',
  '.odm',
  '.odp',
  '.ods',
  '.odt',
  '.one',
  '.otg',
  '.otp',
  '.ots',
  '.ott',
  '.pdf',
  '.png',
  '.pot',
  '.potm',
  '.potx',
  '.ppam',
  '.pps',
  '.ppsm',
  '.ppsx',
  '.ppt',
  '.pptm',
  '.pptx',
  '.psd',
  '.pub',
  '.rar',
  '.rpt',
  '.rtf',
  '.sldm',
  '.sldx',
  '.svg',
  '.tar',
  '.tif',
  '.tiff',
  '.tsv',
  '.txt',
  '.vcf',
  '.xbrl',
  '.xhtml',
  '.xla',
  '.xlam',
  '.xll',
  '.xlm',
  '.xls',
  '.xlsb',
  '.xlsm',
  '.xlsx',
  '.xlt',
  '.xltm',
  '.xltx',
  '.xlw',
  '.xml',
  '.xps',
  '.zip',
];
