import React from 'react';
import cn from 'classnames';
import AntdDrawer from 'antd/lib/drawer';
import AntdConfigProvider from 'antd/lib/config-provider';

import { DrawerProps } from './Drawer.types';
import { Image } from '../Image';
import { getAntdLocale, getIconUrl } from '../../utils';

import './Drawer.scss';

export const Drawer: React.FC<DrawerProps> = ({
  title,
  className,
  closeIcon,
  locale = navigator.language,
  ...rest
}) => {
  const antdLocale = getAntdLocale(locale);

  return (
    <AntdConfigProvider locale={antdLocale}>
      <AntdDrawer
        data-testid="drawer"
        className={cn(
          'lex-drawer',
          !title && 'lex-drawer--no-title',
          className,
        )}
        title={title}
        closeIcon={
          closeIcon || (
            <Image
              className={cn('lex-drawer__close-icon')}
              src={getIconUrl('clear')}
            />
          )
        }
        {...rest}
      />
    </AntdConfigProvider>
  );
};

export default Drawer;
