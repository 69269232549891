import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import AntRadioGroup from 'antd/lib/radio/group';
import AntdConfigProvider from 'antd/lib/config-provider';
import { useFirstMountState } from 'react-use';

import {
  RadioGroupProps,
  RadioOptionType,
  RadioValueType,
} from './RadioGroup.types';
import { RadioChangeEvent } from '../Radio/Radio.types';
import { Radio } from '../Radio';
import { withFormField } from '../../hocs/withFormField';
import { getAntdLocale } from '../../utils';

import './RadioGroup.scss';

export const RadioGroup: React.FC<RadioGroupProps> = ({
  id,
  style,
  className,
  groupClassName,
  radioClassName,
  variant = 'primary',
  disabled,
  block = false,
  options = [],
  value = undefined,
  defaultValue = undefined,
  hiddenLabel,
  error,
  onChange,
  locale = navigator.language,
  ...rest
}) => {
  const isFirstMount = useFirstMountState();
  const [checkedValue, setCheckedValue] = useState<RadioValueType>();

  const antdLocale = getAntdLocale(locale);

  useEffect(() => {
    setCheckedValue(isFirstMount ? value ?? defaultValue : value);
  }, [value, defaultValue]);

  const radios = useMemo(
    () =>
      options.map((option) => {
        const { value, label } = option as RadioOptionType;
        return (
          <div key={`${value}`}>
            <Radio
              id={`${id}-${value}`}
              data-testid="radio-group-radio"
              className={cn('lex-radio-group__radio', radioClassName)}
              value={value}
              variant={variant}
              disabled={disabled}
              error={error}
            >
              {label}
            </Radio>
          </div>
        );
      }),
    [options, id, radioClassName, variant, disabled, error],
  );

  const handleChange = (e: RadioChangeEvent) => {
    setCheckedValue(e.target.value);
    onChange?.(e);
  };

  return (
    <div
      data-testid="radio-group"
      className={cn('lex-radio-group', className)}
      style={style}
    >
      {hiddenLabel && (
        <label className="screen-reader" htmlFor={id}>
          {hiddenLabel}
        </label>
      )}
      <AntdConfigProvider locale={antdLocale}>
        <AntRadioGroup
          id={id}
          data-testid="radio-group-group"
          className={cn(
            'lex-radio-group__group',
            block && 'lex-radio-group__group--block',
            error && 'lex-radio-group__group--error',
            disabled && 'lex-radio-group__group--disabled',
            groupClassName,
          )}
          value={checkedValue}
          disabled={disabled}
          onChange={handleChange}
          {...rest}
        >
          {radios}
        </AntRadioGroup>
      </AntdConfigProvider>
    </div>
  );
};

export default RadioGroup;

export const RadioGroupFormField = withFormField(RadioGroup);
