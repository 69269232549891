import React from 'react';
import cn from 'classnames';
import AntdPopconfirm from 'antd/lib/popconfirm';
import AntdConfigProvider from 'antd/lib/config-provider';

import { PopconfirmProps } from './Popconfirm.types';
import { getAntdLocale } from '../../utils';

import './Popconfirm.scss';

export const Popconfirm: React.FC<PopconfirmProps> = ({
  className,
  titleClassName,
  icon,
  title,
  locale = navigator.language,
  ...rest
}) => {
  const antdLocale = getAntdLocale(locale);

  return (
    <AntdConfigProvider locale={antdLocale}>
      <AntdPopconfirm
        data-testid="popconfirm"
        overlayClassName={cn('lex-popconfirm', className)}
        destroyTooltipOnHide
        icon={null}
        title={
          <div className={cn('lex-popconfirm__title', titleClassName)}>
            {icon && (
              <div className={cn('lex-popconfirm__title-icon')}>{icon}</div>
            )}
            <div className={cn('lex-popconfirm__title-message')}>
              {typeof title === 'function' ? title() : title}
            </div>
          </div>
        }
        {...rest}
      />
    </AntdConfigProvider>
  );
};

export default Popconfirm;
