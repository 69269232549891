import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { useDeepCompareEffect } from 'react-use';
import { Table as AntdTable } from 'antd';
import AntdConfigProvider from 'antd/lib/config-provider';
import React, {
  PropsWithChildren,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import Button from '../Button';
import Image from '../Image';
import Popover from '../Popover';
import Spinner from '../Spinner';
import Checkbox from '../Checkbox';
import CheckboxGroup from '../CheckboxGroup';
import Radio from '../Radio';
import Pagination from '../Pagination';
import { getAntdLocale, getIconUrl } from '../../utils';
import { LG_AND_ABOVE } from '../../constants';
import {
  TableProps,
  ColumnsType,
  CompareFn,
  FilterValue,
  SorterResult,
  SortOrder,
  TableCurrentDataSource,
} from './Table.types';

import './Table.scss';

const defAscIconSrc = getIconUrl('arrow_upward');
const defDescIconSrc = getIconUrl('arrow_downward');
const defFilterIconSrc = getIconUrl('filter_list');
const defFilterResetIconSrc = getIconUrl('filter_list_off');
const defArrowIconSrc = getIconUrl('arrow_drop_down');
const defCheckboxIconSrc = getIconUrl('check_box');
const defUncheckedIconSrc = getIconUrl('check_box_outline_blank');
const defIndetermIconSrc = getIconUrl('indeterminate_check_box');
const defCollapsedIconSrc = getIconUrl('expand_more');
const defExpandedIconSrc = getIconUrl('expand_less');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function Table<T extends { [key: string]: any }>({
  type = 'alternating-rows',
  className,
  paginationClassName,
  paginationStyle,
  variant = 'primary',
  selectionBarVariant = 'primary',
  filterDropdownVariant = 'primary',
  loading = false,
  columns = [],
  dataSource = [],
  rowKey = 'id',
  onChange,
  pageSize,
  currentPage = 1,
  total,
  useExternalPagination = true,
  defaultSelectedRowKeys = [],
  defaultSortColumnKey,
  sortColumnKeyOverride, // parent is handling sorting
  defaultSortOrder = 'asc',
  sortOrderOverride, // parent is handling sorting
  noRowsLabel = 'No results found',
  rowSelect,
  rowSelectButton,
  rowSelectWithVisibleColumns,
  rowSelectOverrideSelectAll,
  radioSelect,
  onSelectedRowsChange,
  disabledRowColumnName = 'disabledRow',
  disabledCheckboxColumnName = 'disabledCheckbox',
  childrenColumnName = 'children',
  childRowSelect,
  uncheckedIconSrc,
  indeterminateIconSrc,
  checkedIconSrc,
  selectionArrowIconSrc,
  selectedRowsLabel = 'selected',
  selectPageLabel = 'Select all on this page',
  deselectPageLabel = 'Deselect all on this page',
  selectAllLabel = 'Select all',
  deselectAllLabel = 'Deselect all',
  noSelectionActionsLabel = 'No actions available',
  selectionActions = [],
  expandable,
  collapsedIconSrc,
  expandedIconSrc,
  sortAscIconSrc,
  sortAscLabel = 'Ascending',
  sortDescIconSrc,
  sortDescLabel = 'Descending',
  filterIconSrc,
  filterLabel = 'Filter',
  filterResetIconSrc,
  filterResetLabel = 'Reset',
  spacedRows,
  borderedRows,
  headerBgColor,
  headerTextColor,
  rowOddBgColor,
  rowOddBgHoverColor,
  rowEvenBgColor,
  rowEvenBgHoverColor,
  rowTextColor,
  locale = navigator.language,
  ...rest
}: PropsWithChildren<TableProps<T>>): JSX.Element | null {
  if (!sortAscIconSrc) sortAscIconSrc = defAscIconSrc;
  if (!sortDescIconSrc) sortDescIconSrc = defDescIconSrc;
  if (!filterIconSrc) filterIconSrc = defFilterIconSrc;
  if (!filterResetIconSrc) filterResetIconSrc = defFilterResetIconSrc;
  if (!selectionArrowIconSrc) selectionArrowIconSrc = defArrowIconSrc;
  if (!uncheckedIconSrc) uncheckedIconSrc = defUncheckedIconSrc;
  if (!indeterminateIconSrc) indeterminateIconSrc = defIndetermIconSrc;
  if (!checkedIconSrc) checkedIconSrc = defCheckboxIconSrc;
  if (!collapsedIconSrc) collapsedIconSrc = defCollapsedIconSrc;
  if (!expandedIconSrc) expandedIconSrc = defExpandedIconSrc;

  const tableDiv = useRef<HTMLDivElement>(null);
  const isDeviceLg = useMediaQuery({ query: LG_AND_ABOVE });
  const [page, setPage] = useState(currentPage);
  const [data, setData] = useState(dataSource);
  const [barHeight, setBarHeight] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [sorter, setSorter] = useState<SorterResult<T>>({
    columnKey: defaultSortColumnKey,
    order: defaultSortOrder as SortOrder,
  });
  const [filterValues, setFilterValues] = useState<
    Record<string, FilterValue | null>
  >({});

  const rowKeyStr = rowKey as string;
  const sortColumnKey = (sortColumnKeyOverride || sorter.columnKey) as string;
  const sortOrder = sortOrderOverride || sorter.order || defaultSortOrder;

  const antdLocale = getAntdLocale(locale);

  useEffect(() => {
    // If new currentPage prop value is passed in
    setPage(currentPage);
  }, [currentPage]);

  useDeepCompareEffect(() => {
    // If new rowKey or dataSource prop values are passed in
    setData(dataSource);
  }, [rowKey, dataSource]);

  useDeepCompareEffect(() => {
    // If new defaultSelectedRowKeys prop value is passed in
    setSelectedRowKeys(defaultSelectedRowKeys);
  }, [defaultSelectedRowKeys]);

  useDeepCompareEffect(() => {
    if (sortColumnKeyOverride || !sortColumnKey || !sortOrder) {
      return;
    }

    const asc = sortOrder === 'asc';

    const sortedData = [...data].sort((a, b) => {
      const col = columns.find((col) => col.key === sortColumnKey);

      if (col?.sorter?.hasOwnProperty('compare')) {
        // Use column provided compare function
        return (col?.sorter as { compare: CompareFn<T> }).compare(a, b);
      }

      if (typeof col?.sorter === 'function') {
        // Use column provided sorting function
        return col?.sorter(a, b);
      }

      // Use a default sorting function
      const val = String(a[sortColumnKey] || '').localeCompare(
        String(b[sortColumnKey] || ''),
        undefined,
        {
          numeric: true,
          sensitivity: 'base',
        },
      );

      return asc ? val : -val;
    });

    setData(sortedData);
  }, [sortColumnKey, sortOrder, data]);

  useEffect(() => {
    const totalCount =
      useExternalPagination || hasFilteredValue
        ? filteredData.length
        : total ?? 0;

    // Reset page if total is less than current page based on size
    if (page && pageSize && (page - 1) * pageSize > totalCount) {
      setPage(1);
    }
  });

  useEffect(() => {
    if (!onSelectedRowsChange) {
      return;
    }

    const selectedRows = filteredData.filter((d) =>
      selectedRowKeys.includes(d[rowKeyStr] as React.Key),
    );

    onSelectedRowsChange(selectedRowKeys, selectedRows);
  }, [selectedRowKeys]);

  useLayoutEffect(() => {
    if (!tableDiv.current) {
      return;
    }

    if (headerBgColor) {
      tableDiv.current
        .querySelectorAll('.ant-table-thead > tr > th')
        .forEach((row) => {
          (row as HTMLElement).style.background = headerBgColor;
        });
    }

    if (headerTextColor) {
      tableDiv.current
        .querySelectorAll('.ant-table-thead > tr > th')
        .forEach((row) => {
          (row as HTMLElement).style.color = headerTextColor;
          (row as HTMLElement).style.fill = headerTextColor;
        });
    }

    /* 
      NOTE: Expandable rows ruin odd/even logic.
      Also, hidden tr.ant-table-measure-row makes odd/even logic backwards .
    */

    if (rowOddBgColor) {
      tableDiv.current
        .querySelectorAll('.ant-table-tbody > tr:nth-child(even)')
        .forEach((row) => {
          (row as HTMLElement).style.background = rowOddBgColor;
        });
    }

    if (rowOddBgHoverColor) {
      tableDiv.current
        .querySelectorAll('.ant-table-tbody > tr:nth-child(even)')
        .forEach((row) => {
          (row as HTMLElement).addEventListener('mouseenter', () => {
            (row as HTMLElement).style.background = rowOddBgHoverColor;
          });
          (row as HTMLElement).addEventListener('mouseleave', () => {
            if (rowOddBgColor) {
              (row as HTMLElement).style.background = rowOddBgColor;
            } else {
              (row as HTMLElement).style.removeProperty('background');
            }
          });
        });
    }

    if (rowEvenBgColor) {
      tableDiv.current
        .querySelectorAll('.ant-table-tbody > tr:nth-child(odd)')
        .forEach((row) => {
          (row as HTMLElement).style.background = rowEvenBgColor;
        });
    }

    if (rowEvenBgHoverColor) {
      tableDiv.current
        .querySelectorAll('.ant-table-tbody > tr:nth-child(odd)')
        .forEach((row) => {
          (row as HTMLElement).addEventListener('mouseenter', () => {
            (row as HTMLElement).style.background = rowEvenBgHoverColor;
          });
          (row as HTMLElement).addEventListener('mouseleave', () => {
            if (rowEvenBgColor) {
              (row as HTMLElement).style.background = rowEvenBgColor;
            } else {
              (row as HTMLElement).style.removeProperty('background');
            }
          });
        });
    }

    if (rowTextColor) {
      tableDiv.current.style.color = rowTextColor;
      tableDiv.current.style.fill = rowTextColor;
    }

    const headerRow = tableDiv.current.querySelector('.ant-table-thead > tr');

    if (headerRow) {
      let height = headerRow.clientHeight;

      if (height > 0) {
        if (type === 'matching-rows') {
          height += 17;
        }

        if (rowSelectButton) {
          height += 12;
        }

        if (height !== barHeight) {
          setBarHeight(height);
        }
      }
    }
  });

  const overriddenColumns: ColumnsType<T> | undefined = columns.map((col) => ({
    // Render overridable filter dropdown for each filterable column
    filterDropdown: ({
      selectedKeys,
      setSelectedKeys,
      confirm,
      clearFilters,
      filters,
    }) =>
      (col.filters?.length ?? 0) > 0 && (
        <div
          data-testid="table-column-header-filter"
          className={cn(
            'lex-table__filter-dropdown',
            `lex-table__filter-dropdown--${filterDropdownVariant}`,
          )}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <CheckboxGroup
            block
            variant={variant}
            options={filters?.map((f) => ({ value: f.value, label: f.text }))}
            onChange={(checkedValue) => {
              setSelectedKeys(checkedValue as React.Key[]);
            }}
            value={selectedKeys}
          />
          <div className={cn('lex-table__filter-dropdown-actions')}>
            <div
              data-testid="table-column-header-filter-reset"
              className={cn('lex-table__filter-dropdown-actions-reset-btn')}
              onClick={() => {
                clearFilters?.();
                setSelectedKeys([]);
              }}
              title={filterResetLabel}
            >
              <Image src={filterResetIconSrc} />
            </div>
            <Button
              data-testid="table-column-header-filter-filter"
              className={cn('lex-table__filter-dropdown-actions-filter-btn')}
              onClick={() => {
                confirm({ closeDropdown: true });
              }}
            >
              {filterLabel}
            </Button>
          </div>
        </div>
      ),
    ...col,
    // Render non-overridable filter icon for each filterable column
    filterIcon: (filtered) =>
      (col.filters?.length ?? 0) > 0 && (
        <Image
          data-testid="table-column-header-filter-icon"
          className={cn(
            'lex-table__header-cell-icon',
            filtered && 'lex-table__header-cell-icon--active',
          )}
          src={filterIconSrc}
          title={filterLabel}
        />
      ),
    // Render non-overridable sort icons for each sortable column
    title: (props) => {
      if (!props.sortColumns || !col.sorter) {
        return col.title as React.ReactNode;
      }
      const colMatch = sortColumnKey === col.key;
      return (
        <div
          data-testid="table-column-header"
          className={cn('lex-table__header-cell')}
        >
          <span>
            {typeof col.title === 'function' ? col.title(props) : col.title}
          </span>
          {colMatch && sortOrder === 'asc' && (
            <Image
              data-testid="table-column-header-asc"
              className={cn('lex-table__header-cell-icon')}
              src={sortAscIconSrc}
              title={sortAscLabel}
            />
          )}
          {colMatch && sortOrder === 'desc' && (
            <Image
              data-testid="table-column-header-desc"
              className={cn('lex-table__header-cell-icon')}
              src={sortDescIconSrc}
              title={sortDescLabel}
            />
          )}
        </div>
      );
    },
  }));

  let filteredData = data;
  let hasFilteredValue = false;

  Object.keys(filterValues).map((filterKey) => {
    const filterValue = filterValues[filterKey];
    const overriddenCol = overriddenColumns.find((c) => c.key === filterKey);

    if (overriddenCol?.hasOwnProperty('filteredValue')) {
      overriddenCol.filteredValue = filterValue;
    }

    if (!filterValue) {
      return;
    }

    hasFilteredValue = true;

    if (overriddenCol?.hasOwnProperty('onFilter')) {
      // Filter the data based on the filter key
      filteredData = filteredData.filter((d) =>
        filterValue.some((val) => overriddenCol.onFilter?.(val, d)),
      );
    }
  });

  let pagedFilteredData = filteredData;

  // If page size is defined, and the data is more than one page,
  // slice the data for one page
  if (pageSize !== undefined && filteredData.length > pageSize) {
    const begin = (page - 1) * pageSize;
    const end = begin + pageSize;
    pagedFilteredData = filteredData.slice(begin, end);
  }

  const isMultiPage =
    pagedFilteredData.length <
    (useExternalPagination || hasFilteredValue
      ? filteredData.length
      : total ?? 0);

  const popoverContent = (
    <div
      data-testid="table-selection-bar-popover-content"
      className={cn('lex-table__selection-bar-popover-content')}
    >
      {isMultiPage && (
        <>
          <span
            data-testid="table-select-page"
            className={cn('lex-table__selection-item')}
            onClick={() => {
              // TODO: If "childRowSelect", recursively map d.children
              const pageKeys = pagedFilteredData
                .filter(
                  (d) =>
                    !d[disabledRowColumnName] && !d[disabledCheckboxColumnName],
                )
                .map((d) => d[rowKeyStr]);
              const newRowKeys = Array.from(
                new Set([...selectedRowKeys, ...pageKeys]),
              ) as React.Key[];
              setSelectedRowKeys(newRowKeys);
            }}
          >
            {selectPageLabel}
          </span>
          <span
            data-testid="table-deselect-page"
            className={cn('lex-table__selection-item')}
            onClick={() => {
              // TODO: If "childRowSelect", recursively map d.children
              const pageKeys = pagedFilteredData
                .filter(
                  (d) =>
                    !d[disabledRowColumnName] && !d[disabledCheckboxColumnName],
                )
                .map((d) => d[rowKeyStr]);
              const newRowKeys = selectedRowKeys.filter(
                (r) => !pageKeys.includes(r),
              );
              setSelectedRowKeys(newRowKeys);
            }}
          >
            {deselectPageLabel}
          </span>
          <div className={cn('lex-table__selection-item-divider')} />
        </>
      )}
      <span
        data-testid="table-select-all"
        className={cn('lex-table__selection-item')}
        onClick={() => {
          // TODO: If "childRowSelect", recursively map d.children
          const allKeys = data
            .filter(
              (d) =>
                !d[disabledRowColumnName] && !d[disabledCheckboxColumnName],
            )
            .map((d) => d[rowKeyStr]) as React.Key[];
          setSelectedRowKeys(allKeys);
        }}
      >
        {selectAllLabel}
      </span>
      <span
        data-testid="table-deselect-all"
        className={cn('lex-table__selection-item')}
        onClick={() => {
          // TODO: If "childRowSelect", recursively map d.children
          setSelectedRowKeys([]);
        }}
      >
        {deselectAllLabel}
      </span>
    </div>
  );

  const enabledData = filteredData.filter(
    (d) => !d[disabledRowColumnName] && !d[disabledCheckboxColumnName],
  );
  const headerChecked =
    selectedRowKeys.length > 0 && selectedRowKeys.length === enabledData.length;
  const headerIndeterminate =
    selectedRowKeys.length > 0 && selectedRowKeys.length < enabledData.length;

  const popover = (
    <Popover
      variant={selectionBarVariant}
      placement="bottomLeft"
      trigger="click"
      arrowPointAtCenter
      className={cn('lex-table__selection-bar-popover')}
      content={popoverContent}
    >
      <div
        data-testid="table-selection-bar-items"
        className={cn(
          'lex-table__selection-bar-items',
          'lex-table__selection-item',
        )}
      >
        <Checkbox
          variant={variant}
          onChange={(e) => e.preventDefault}
          checked={headerChecked}
          indeterminate={headerIndeterminate}
        />
        {/*
          When there is a rowSelectButton, display the select popover options
          and selectedRowsLabelin the select bar when none are selected so any
          changes can be saved
        */}
        {(selectedRowKeys.length > 0 || rowSelectButton) && (
          <>
            <span className={cn('lex-table__selection-bar-items-selected')}>
              {selectedRowKeys.length}&nbsp;{selectedRowsLabel}
            </span>
            <div className={cn('lex-table__selection-bar-items-image')}>
              <Image src={selectionArrowIconSrc} />
            </div>
          </>
        )}
      </div>
    </Popover>
  );

  const selectSummary = (
    <span>
      {selectedRowKeys.length}&nbsp;{selectedRowsLabel}
    </span>
  );

  let bar;

  /*
    When there is a rowSelectButton, display the select bar and its actions with
    the bar actions even when none are selected so any changes can be saved
  */
  if ((selectedRowKeys.length > 0 || rowSelectButton) && !radioSelect) {
    const barActions =
      selectionActions.length === 0 ? (
        <span
          className={cn(
            'lex-table__selection-bar-action',
            'lex-table__selection-item',
            'lex-table__selection-item--disabled',
          )}
        >
          {noSelectionActionsLabel}
        </span>
      ) : (
        selectionActions.map(({ onClick, disabled, ...rest }, idx) => (
          <div
            key={idx}
            role="button"
            tabIndex={0}
            data-testid="table-selection-bar-action"
            className={cn(
              'lex-table__selection-bar-action',
              'lex-table__selection-item',
              disabled && 'lex-table__selection-item--disabled',
            )}
            onClick={disabled ? undefined : () => onClick(selectedRowKeys)}
            {...rest}
          />
        ))
      );

    bar = (selectedRowKeys.length > 0 || rowSelectButton) && (
      <div
        data-testid="table-selection-bar"
        className={cn(
          'lex-table__selection-bar',
          `lex-table__selection-bar--${selectionBarVariant}`,
          rowSelectButton && 'lex-table__selection-bar-button',
          rowSelectWithVisibleColumns && 'lex-table__selection-visible-columns',
        )}
        style={{ height: barHeight }}
      >
        <div className={cn('lex-table__selection-bar-left')} role="button">
          {rowSelectOverrideSelectAll ? selectSummary : popover}
        </div>
        <div className={cn('lex-table__selection-bar-right')}>{barActions}</div>
      </div>
    );
  }

  const handleSelect = (value: React.Key, checked: boolean) => {
    let newRowKeys = [...selectedRowKeys];
    if (radioSelect) {
      setSelectedRowKeys([value]);
    } else {
      if (checked) {
        newRowKeys.push(value);
      } else {
        newRowKeys = newRowKeys.filter((r) => String(r) !== String(value));
      }

      setSelectedRowKeys(newRowKeys);
    }
  };

  const someDataEnabled = filteredData.some(
    (d) => !d[disabledRowColumnName] && !d[disabledCheckboxColumnName],
  );

  const getEmptyText = () => {
    if (loading || !noRowsLabel) {
      return <></>;
    }

    if (React.isValidElement(noRowsLabel)) {
      return noRowsLabel;
    }

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: noRowsLabel as string,
        }}
      />
    );
  };

  // Removing this summary when there is a rowSelectButton
  const showHeaderCheckbox =
    someDataEnabled && selectedRowKeys.length === 0 && !rowSelectButton;
  const topLevelKeys = pagedFilteredData.map((d) => d[rowKeyStr]);

  // Space colored rows by default
  let spaced = type === 'matching-rows';

  // Allow for override
  if (spacedRows !== undefined) {
    spaced = spacedRows;
  }

  return (
    <Spinner size="small" spinning={loading}>
      <div
        data-testid="table"
        className={cn(
          'lex-table',
          `lex-table--${variant}`,
          `lex-table--${type}`,
          rowSelectButton &&
            !rowSelectWithVisibleColumns &&
            'lex-table--large-header',
          columns.every((col) => !col.title) && 'lex-table--no-thead',
          spaced && `lex-table--spaced-rows`,
          borderedRows && `lex-table--bordered-rows`,
          className,
        )}
        ref={tableDiv}
      >
        {!useExternalPagination && (
          <Pagination
            className={cn('lex-table__pagination', paginationClassName)}
            style={paginationStyle}
            pageSize={pageSize}
            current={page}
            onChange={(newPage) => {
              setPage(newPage);
              onChange?.(
                { current: newPage, pageSize },
                filterValues,
                sorter,
                {} as TableCurrentDataSource<T>,
              );
            }}
            total={hasFilteredValue ? filteredData.length : total}
          />
        )}
        <div>
          {bar}
          <AntdConfigProvider locale={antdLocale}>
            <AntdTable
              columns={overriddenColumns}
              dataSource={pagedFilteredData}
              rowKey={rowKey}
              scroll={{ x: isDeviceLg ? true : 'max-content' }}
              pagination={false}
              sortDirections={[
                'asc' as SortOrder,
                'desc' as SortOrder,
                'asc' as SortOrder,
              ]}
              locale={{
                emptyText: getEmptyText(),
                triggerDesc: '',
                triggerAsc: '',
                cancelSort: '',
              }}
              onChange={(_pagination, filterValues, sorter, extra) => {
                if (extra.action === 'sort' && !sortColumnKeyOverride) {
                  setSorter(sorter as SorterResult<T>);
                }

                if (extra.action === 'filter') {
                  setFilterValues(filterValues);
                }

                onChange?.(
                  { current: page, pageSize },
                  filterValues,
                  sorter,
                  extra,
                );
              }}
              rowClassName={(record) => {
                const isRowEnabled = !record[disabledRowColumnName];
                return isRowEnabled ? '' : 'lex-table--row-disabled';
              }}
              rowSelection={
                !rowSelect
                  ? undefined
                  : {
                      selectedRowKeys,
                      type: radioSelect ? 'radio' : undefined,
                      columnTitle: (
                        <div
                          className={cn('lex-table__selection-header')}
                          role="button"
                        >
                          {showHeaderCheckbox && !radioSelect && popover}
                        </div>
                      ),
                      onSelect: (record, selected) => {
                        handleSelect(record[rowKeyStr], selected);
                      },
                      renderCell: childRowSelect
                        ? undefined
                        : (selected, record) => {
                            const isTopLevel = topLevelKeys.includes(
                              record[rowKeyStr],
                            );
                            const isRowEnabled = !record[disabledRowColumnName];
                            const showRowCheckbox = isTopLevel && isRowEnabled;

                            if (!showRowCheckbox) {
                              return null;
                            }

                            const isCheckboxEnabled =
                              !record[disabledCheckboxColumnName];

                            if (radioSelect) {
                              return (
                                <Radio
                                  variant={variant}
                                  checked={selected}
                                  disabled={!isCheckboxEnabled}
                                  onChange={(e) => {
                                    const { checked } = e.target;
                                    handleSelect(record[rowKeyStr], checked);
                                  }}
                                />
                              );
                            } else {
                              return (
                                <Checkbox
                                  variant={variant}
                                  checked={isCheckboxEnabled && selected}
                                  disabled={!isCheckboxEnabled}
                                  onChange={(e) => {
                                    const { checked } = e.target;
                                    handleSelect(record[rowKeyStr], checked);
                                  }}
                                />
                              );
                            }
                          },
                    }
              }
              expandable={{
                indentSize: 40,
                expandIcon: function expandIcon({
                  expanded,
                  onExpand,
                  record,
                }) {
                  const topLevelKeys = pagedFilteredData.map(
                    (d) => d[rowKeyStr],
                  );
                  const isTopLevel = topLevelKeys.includes(record[rowKeyStr]);
                  const hasChildren = record[childrenColumnName];

                  const canExpand =
                    (isTopLevel && hasChildren) ||
                    expandable?.rowExpandable?.(record);

                  return !canExpand ? null : (
                    <div className={cn('lex-table__expandable-image')}>
                      <Image
                        src={expanded ? expandedIconSrc : collapsedIconSrc}
                        onClick={(
                          e:
                            | React.MouseEvent<SVGElement, MouseEvent>
                            | React.MouseEvent<HTMLImageElement, MouseEvent>,
                        ) =>
                          onExpand(
                            record,
                            e as React.MouseEvent<HTMLElement, MouseEvent>,
                          )
                        }
                      />
                    </div>
                  );
                },
                ...expandable,
              }}
              {...rest}
            />
          </AntdConfigProvider>
        </div>
      </div>
    </Spinner>
  );
}

export default Table;
