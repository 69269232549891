import cn from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import Image from '../Image';
import { StepperProps, StepStatus } from './Stepper.types';
import { getIconUrl } from '../../utils';

import './Stepper.scss';

const defErrorIconSrc = getIconUrl('warning');
const defApprovedIconSrc = getIconUrl('check');
const defRejectedIconSrc = getIconUrl('close');
const defReturnedIconSrc = getIconUrl('keyboard_return');
const defRecordedIconSrc = getIconUrl('check_circle');
const defSkippedIconSrc = getIconUrl('shortcut');
const defNextIconVerticalSrc = getIconUrl('expand_more');
const defNextIconHoriztalSrc = getIconUrl('chevron_right');

const getSafeNumber = (num: number | undefined) =>
  num !== undefined ? num : 0;

export const Stepper: React.FC<StepperProps> = ({
  id,
  style,
  stepStyle,
  stepCircleStyle,
  stepTitleStyle,
  stepSubtitleStyle,
  stepNextStyle,
  className,
  stepClassName,
  stepCircleClassName,
  stepTitleClassName,
  stepSubtitleClassName,
  stepNextClassName,
  variant = 'primary',
  orientation = 'vertical',
  steps = [],
  activeStepId,
  useConnector = true,
  connectorSize = 2,
  iconSize = 'small',
  errorIconSrc,
  approvedIconSrc,
  rejectedIconSrc,
  returnedIconSrc,
  recordedIconSrc,
  skippedIconSrc,
  nextIconSrc,
  notStartedIconTitle,
  inProgressIconTitle,
  errorIconTitle,
  approvedIconTitle,
  rejectedIconTitle,
  returnedIconTitle,
  recordedIconTitle,
  skippedIconTitle,
}) => {
  if (!errorIconSrc) errorIconSrc = defErrorIconSrc;
  if (!approvedIconSrc) approvedIconSrc = defApprovedIconSrc;
  if (!rejectedIconSrc) rejectedIconSrc = defRejectedIconSrc;
  if (!returnedIconSrc) returnedIconSrc = defReturnedIconSrc;
  if (!recordedIconSrc) recordedIconSrc = defRecordedIconSrc;
  if (!skippedIconSrc) skippedIconSrc = defSkippedIconSrc;
  if (!nextIconSrc)
    nextIconSrc =
      orientation === 'vertical'
        ? defNextIconVerticalSrc
        : defNextIconHoriztalSrc;

  const firstStepRef = useRef<HTMLDivElement>(null);
  const lastStepRef = useRef<HTMLDivElement>(null);

  const [firstStepRect, setFirstStepRect] = useState<DOMRect | undefined>();
  const [lastStepRect, setLastStepRect] = useState<DOMRect | undefined>();

  useEffect(() => {
    const setRects = () => {
      setFirstStepRect(firstStepRef.current?.getBoundingClientRect());
      setLastStepRect(lastStepRef.current?.getBoundingClientRect());
    };
    setTimeout(setRects, 0);
    const intervalId = setInterval(setRects, 1000);
    return () => clearInterval(intervalId);
  }, [firstStepRef.current, lastStepRef.current, steps, orientation]);

  const getStepIconTitle = useCallback(
    (status?: StepStatus) => {
      switch (status) {
        case StepStatus.IN_PROGRESS:
          return inProgressIconTitle;
        case StepStatus.ERROR:
          return errorIconTitle;
        case StepStatus.APPROVED:
          return approvedIconTitle;
        case StepStatus.REJECTED:
          return rejectedIconTitle;
        case StepStatus.RETURNED:
          return returnedIconTitle;
        case StepStatus.RECORDED:
          return recordedIconTitle;
        case StepStatus.SKIPPED:
          return skippedIconTitle;
        default:
          return notStartedIconTitle;
      }
    },
    [
      inProgressIconTitle,
      errorIconTitle,
      approvedIconTitle,
      rejectedIconTitle,
      returnedIconTitle,
      recordedIconTitle,
      skippedIconTitle,
      notStartedIconTitle,
    ],
  );

  const getStepIconUrl = useCallback(
    (status?: StepStatus) => {
      switch (status) {
        case StepStatus.ERROR:
          return errorIconSrc;
        case StepStatus.APPROVED:
          return approvedIconSrc;
        case StepStatus.REJECTED:
          return rejectedIconSrc;
        case StepStatus.RETURNED:
          return returnedIconSrc;
        case StepStatus.RECORDED:
          return recordedIconSrc;
        case StepStatus.SKIPPED:
          return skippedIconSrc;
        default:
          return;
      }
    },
    [
      errorIconSrc,
      approvedIconSrc,
      rejectedIconSrc,
      returnedIconSrc,
      recordedIconSrc,
      skippedIconSrc,
    ],
  );

  const renderConnector = useCallback(() => {
    if (!firstStepRect || !lastStepRect) {
      return null;
    }

    let top, left, width, height;

    if (orientation === 'vertical') {
      top = getSafeNumber(firstStepRect?.height) / 2;
      left = getSafeNumber(firstStepRect?.width) / 2 - connectorSize / 2;
      width = connectorSize;
      height =
        getSafeNumber(lastStepRect?.top) - getSafeNumber(firstStepRect?.top);
    } else {
      top = getSafeNumber(firstStepRect?.height) / 2;
      left = getSafeNumber(firstStepRect?.width) / 2 - connectorSize / 2;
      width =
        getSafeNumber(lastStepRect?.left) - getSafeNumber(firstStepRect?.left);
      height = connectorSize;
    }

    return (
      <div
        className={cn('lex-stepper__step-circle-connector')}
        style={{
          top,
          left,
          width,
          height,
        }}
      ></div>
    );
  }, [
    JSON.stringify(firstStepRect),
    JSON.stringify(lastStepRect),
    orientation,
    connectorSize,
  ]);

  const stepDivs = useMemo(
    () =>
      steps.map((step, idx) => {
        const { id, title, subtitle, status = StepStatus.NOT_STARTED } = step;
        const stepIconTitle = getStepIconTitle(status);
        const stepIconUrl = getStepIconUrl(status);

        const isFirstStep = idx === 0;
        const isLastStep = idx === steps.length - 1;
        const showConnector = useConnector && steps.length > 1 && isFirstStep;
        const showNextIcon = !useConnector && !isLastStep;

        const nextIcon = showNextIcon && (
          <div
            className={cn(
              'lex-stepper__step-next-icon',
              `lex-stepper__step-next-icon--${iconSize}`,
              stepNextClassName,
            )}
            style={stepNextStyle}
          >
            <Image src={nextIconSrc} />
          </div>
        );

        let stepDiv = (
          <div
            key={`step-${id}`}
            data-testid="stepper-step"
            className={cn(
              'lex-stepper__step',
              stepClassName,
              id === activeStepId && 'lex-stepper__step--active',
              status && `lex-stepper__step--${status}`,
            )}
            style={stepStyle}
          >
            <div
              className={cn(
                'lex-stepper__step-circle',

                stepCircleClassName,
              )}
              style={stepCircleStyle}
              ref={
                isFirstStep
                  ? firstStepRef
                  : isLastStep
                  ? lastStepRef
                  : undefined
              }
            >
              <div
                className={cn(
                  'lex-stepper__step-circle-icon',
                  `lex-stepper__step-circle-icon--${iconSize}`,
                )}
                title={stepIconTitle}
              >
                <Image src={stepIconUrl} />
              </div>
              {showConnector && renderConnector()}
            </div>
            <div className={cn('lex-stepper__step-title-container')}>
              <div
                className={cn('lex-stepper__step-title', stepTitleClassName)}
                style={stepTitleStyle}
              >
                {title}
              </div>
              {subtitle && (
                <div
                  className={cn(
                    'lex-stepper__step-subtitle',
                    stepSubtitleClassName,
                  )}
                  style={stepSubtitleStyle}
                >
                  {subtitle}
                </div>
              )}
            </div>
            {orientation === 'horizontal' && nextIcon}
          </div>
        );

        if (orientation === 'vertical' && nextIcon) {
          stepDiv = (
            <div
              key={`step-container-${id}`}
              data-testid="stepper-step-container"
            >
              {stepDiv}
              {nextIcon}
            </div>
          );
        }

        return stepDiv;
      }),
    [
      steps,
      stepClassName,
      activeStepId,
      stepStyle,
      stepCircleClassName,
      stepCircleStyle,
      firstStepRef,
      lastStepRef,
      iconSize,
      JSON.stringify(firstStepRect),
      JSON.stringify(lastStepRect),
      orientation,
      useConnector,
      connectorSize,
      stepTitleClassName,
      stepTitleStyle,
      stepSubtitleClassName,
      stepSubtitleStyle,
    ],
  );

  return (
    <div
      id={id}
      data-testid="stepper"
      className={cn(
        'lex-stepper',
        `lex-stepper--${variant}`,
        `lex-stepper--${orientation}`,
        useConnector && 'lex-stepper--connector',
        className,
      )}
      style={style}
    >
      <div className={cn('lex-stepper__steps')}>{stepDivs}</div>
    </div>
  );
};

export default Stepper;
